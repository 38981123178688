import { atom } from "recoil";

export const countState = atom({
  key: "countState",
  default: 0,
});

export const PrototipModalState = atom({
  key: "prototipModalState",
  default: false,
});

export const PrototipModalData = atom({
  key: "prototipModalData",
  default: {},
});
export const TaramaModalState = atom({
  key: "taramaModalState",
  default: false,
});

export const TaramaModalData = atom({
  key: "taramaModalData",
  default: {},
});

export const counterExample = atom({
  key: "2",
  default: 0,
});

import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { FirebaseApp } from "../../../FirebaseConfig";
import axios from "axios";
import moment from "moment";
import "moment/locale/tr";
import LoadingScreen from "../../LoadingScreen";

import { StlViewer } from "react-stl-viewer";
import {
  kaliteKontrolHizmetIndir,
  prototipHizmetiIndir,
  taramaHizmetiIndir,
} from "./DownloadFunc";

function CustomerDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const prototipDialogRef = useRef(null);
  const taramaDialogRef = useRef(null);
  const kaliteDialogRef = useRef(null);
  const [stlFinish, setStlFinish] = useState(false);

  const [epostaGonderLoading, setEpostaGonderLoading] = useState(false);
  const [epostaKonu, setEpostaKonu] = useState("");
  const [epostaMesaj, setEpostaMesaj] = useState("");
  const userId = location.state ? location.state.userId : null;
  const [odemeLink, setOdemeLink] = useState("");
  const isim = location.state ? location.state.isim : null;
  const eposta = location.state ? location.state.eposta : null;
  const telefonNumarasi = location.state ? location.state.telefon : null;
  const firmaIsmi = location.state ? location.state.firmaIsmi : null;
  const [teklifGonderLoading, setTeklifGonderLoading] = useState(false);
  const [teklifPrototipFiyat, setTeklifPrototipFiyat] = useState(0);
  const [teklifPrototipFiyatToplam, setTeklifPrototipFiyatToplam] = useState(0);

  const [teklifTaramaFiyat, setTeklifTaramaFiyat] = useState(0);
  const [teklifTaramaFiyatToplam, setTeklifTaramaFiyatToplam] = useState(0);

  const [teklifKaliteFiyat, setTeklifKaliteFiyat] = useState(0);
  const [teklifKaliteFiyatToplam, setTeklifKaliteFiyatToplam] = useState(0);

  const [modalVeri, setModalVeri] = useState(null);

  //const prototipModal = document.getElementById("my_modal_4");

  let taramaHizmeti = query(
    collection(getFirestore(FirebaseApp), `USERS/${userId}/taramaModelleme`),
    orderBy("zaman", "desc")
  );
  const [taramaVal, taramaLoad, taramaErr] = useCollectionData(taramaHizmeti);

  let prototipHizmeti = query(
    collection(getFirestore(FirebaseApp), `USERS/${userId}/prototipHizmeti`),
    orderBy("zaman", "desc")
  );
  const [prototipVal, prototipLoad, prototipErr] =
    useCollectionData(prototipHizmeti);

  let kaliteKontrol = query(
    collection(getFirestore(FirebaseApp), `USERS/${userId}/kaliteKontrol`),
    orderBy("zaman", "desc")
  );
  const [kaliteVal, kaliteLoad, kaliteErr] = useCollectionData(kaliteKontrol);

  const prototipHizmetiHesap = (e) => {
    const fiyat = parseFloat(e.target.value); // Girilen değeri sayıya dönüştür
    setTeklifPrototipFiyat(fiyat); // Dönüştürülmüş değeri state'e atar
    const kdvHesap = (fiyat * 20) / 100;
    const kdvToplamHesap = fiyat + kdvHesap;
    setTeklifPrototipFiyatToplam(kdvToplamHesap);
  };

  const taramaHizmetHesap = (e) => {
    const fiyat = parseFloat(e.target.value); // Girilen değeri sayıya dönüştür
    setTeklifTaramaFiyat(fiyat); // Dönüştürülmüş değeri state'e atar
    const kdvHesap = (fiyat * 20) / 100;
    const kdvToplamHesap = fiyat + kdvHesap;
    setTeklifTaramaFiyatToplam(kdvToplamHesap);
  };

  const kaliteHizmetHesap = (e) => {
    const fiyat = parseFloat(e.target.value); // Girilen değeri sayıya dönüştür
    setTeklifKaliteFiyat(fiyat); // Dönüştürülmüş değeri state'e atar
    const kdvHesap = (fiyat * 20) / 100;
    const kdvToplamHesap = fiyat + kdvHesap;
    setTeklifKaliteFiyatToplam(kdvToplamHesap);
  };

  const mailGonder = async () => {
    setEpostaGonderLoading(true);
    const data = {
      toMail: eposta,
      headerData: epostaKonu,
      bodyData: `	<!DOCTYPE html>
            <html lang="tr">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>E-posta Tasarımı</title>
            </head>
            
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4;">
                <div style="text-align: center; padding: 20px;">
                <img src="https://firebasestorage.googleapis.com/v0/b/robohive-9c678.appspot.com/o/weblogo.jpg?alt=media&token=5d7bd842-cfad-4dbb-a8d4-ca73b37e35cc"
                height="80px" />
                    <p style="font-size: 18px;">Merhaba,<br>${epostaMesaj}</p>
                </div>
            </body>
            
            </html>
            `,
    };

    try {
      await axios.post("https://nodemail.calybre3d.com/mail/teklif", data);

      setEpostaKonu("");
      setEpostaMesaj("");
      setEpostaGonderLoading(false);
    } catch (error) {
      setEpostaGonderLoading(false);
      alert("Hata Oluştu, tekrar deneyiniz");
    }
  };

  const prototipHizmetiMailSend = async () => {
    setTeklifGonderLoading(true);
    const data = {
      toMail: eposta,
      headerData: "Prototip Hizmeti",
      bodyData: `<!DOCTYPE html>
            <html lang="tr">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>RoboHive</title>
            </head>
            
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4;">
                <div
                    style="max-width: 800px; margin: 0 auto; padding: 20px; background-color: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);">
                    <div class="logo-size" style="text-align: center; align-items: center; margin-bottom: 40px;">
                        <img src="https://firebasestorage.googleapis.com/v0/b/robohive-9c678.appspot.com/o/weblogo.jpg?alt=media&token=5d7bd842-cfad-4dbb-a8d4-ca73b37e35cc"
                            height="80px" />
                    </div>
                    <div class="content-header" style="text-align: center; color: #333;">
                        <h1 style="margin: 0; font-size: 28px;">Fiyat Teklifi</h1>
                    </div>
                    <div class="paragraph-container" style="text-align: center; padding: 0 20px;">
                        <p class="paragraph" style="display: inline-block; text-align: left; max-width: 100%;">
                            <span style="display: inline-block; white-space: nowrap; font-size: 15px;">Prototipleme Hizmeti :<span
                                    style="color: rgb(158, 152, 152);"> ${
                                      modalVeri.dosyaIsmi
                                    }</span></span>
                        </p>
                    </div>
            
                    <div style="width: 100%; align-items: center; text-align: start;">
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px; "> Malzeme Çeşidi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.malzemeCesidi
                                    }</span></span>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px;"> Baskı Teknolojisi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.baskiTeknolojisi
                                    }</span></span>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px;"> Adet <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.malzemeAdet
                                    }</span></span>
                        </div>
            
                    </div>
                    <div
                        style="display: flex; justify-content: space-between; align-items: center;
                                        width: 100%;border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;margin-top: 30px;">
                        <span style="font-size: 18px;">Hizmet Bedeli</span>
                        <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">${teklifPrototipFiyat} TL</span>
                    </div>
                    <div class="price-details"
                        style="display: flex; width: 100%; justify-content: space-between; align-items: center; border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;">
                        <span style="font-size: 18px;">KDV</span>
                        <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">%20</span>
                    </div>
                    <div class="total" style="text-align: right; margin-top: 20px;">
                        <span style="font-size: 18px;">Toplam</span>
                        <span class="price"
                            style="font-size: 28px; font-weight: bold; color: #333;">${teklifPrototipFiyatToplam.toFixed(
                              2
                            )}TL</span>
                    </div>
                    <div style="width: 100%; text-align: center; justify-content: center;  align-items: center; ">
                        <div style="text-align: center; margin-top:30px;">
                        <a
                        href=${odemeLink}
                          style="background-color: forestgreen; display: inline-block; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; font-size: 16px; border-radius: 5px; cursor: pointer; ">Ödemeye
                          Geç</a>
                        </div>
                    </div>
                </div>
            </body>
            
            </html>
            `,
    };

    try {
      const response = await axios.post(
        "https://nodemail.calybre3d.com/mail/teklif",
        data
      );

      setTeklifGonderLoading(false);
      alert("Fiyat Teklifi Gönderildi");
      prototipDialogRef.current.close();
      teklifPrototipFiyatToplam(0);
      setTeklifPrototipFiyatToplam(0);
      setOdemeLink("");
      setModalVeri(null);
    } catch (error) {
      setTeklifGonderLoading(false);
      alert("Hata Oluştu, tekrar deneyiniz");
    }
  };

  const kaliteKontrolMailSend = async () => {
    setTeklifGonderLoading(true);
    const data = {
      toMail: eposta,
      headerData: "Kalite Kontrol Hizmeti",
      bodyData: `
    <!DOCTYPE html>
          <html lang="tr">
          
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>RoboHive</title>
          </head>
          
          <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4;">
              <div
                  style="max-width: 800px; margin: 0 auto; padding: 20px; background-color: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);">
                  <div class="logo-size" style="text-align: center; align-items: center; margin-bottom: 40px;">
                      <img src="https://firebasestorage.googleapis.com/v0/b/robohive-9c678.appspot.com/o/weblogo.jpg?alt=media&token=5d7bd842-cfad-4dbb-a8d4-ca73b37e35cc"
                          height="80px" />
                  </div>
                  <div class="content-header" style="text-align: center; color: #333;">
                      <h1 style="margin: 0; font-size: 28px;">Fiyat Teklifi</h1>
                  </div>
                  <div class="paragraph-container" style="text-align: center; padding: 0 20px;">
                  
                  </div>
          
                  <div style="width: 100%; align-items: center; text-align: start;">
                      <div style="margin-top: 10px;">
                         
                      </div>
               
                      <div style="margin-top: 10px;">
                      <span style="font-size: 13px;">Parca Sayisi <span
                              style="color: rgb(158, 152, 152);">${
                                modalVeri.olculecekParcaSayisi
                              }</span></span>
                  </div>

                      <div style="margin-top: 10px;">
                          <span style="font-size: 13px;"> Tarih <span
                                  style="color: rgb(158, 152, 152);">${moment(
                                    modalVeri.zaman
                                  ).calendar()}</span></span>
                      </div>
          
                  </div>
                  <div
                      style="display: flex; justify-content: space-between; align-items: center;
                                      width: 100%;border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;margin-top: 30px;">
                      <span style="font-size: 18px;">Hizmet Bedeli</span>
                      <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">${teklifKaliteFiyat} TL</span>
                  </div>
                  <div class="price-details"
                      style="display: flex; width: 100%; justify-content: space-between; align-items: center; border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;">
                      <span style="font-size: 18px;">KDV</span>
                      <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">%20</span>
                  </div>
                  <div class="total" style="text-align: right; margin-top: 20px;">
                      <span style="font-size: 18px;">Toplam</span>
                      <span class="price"
                          style="font-size: 28px; font-weight: bold; color: #333;">${teklifKaliteFiyatToplam.toFixed(
                            2
                          )}TL</span>
                  </div>
                  <div style="width: 100%; text-align: center; justify-content: center;  align-items: center; ">
                      <div style="text-align: center; margin-top:30px;">
                      <a
                      href=${odemeLink}
                        style="background-color: forestgreen; display: inline-block; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; font-size: 16px; border-radius: 5px; cursor: pointer; ">Ödemeye
                        Geç</a>
                      </div>
                  </div>
              </div>
          </body>
          
          </html>
          `,
    };

    try {
      const response = await axios.post(
        "https://nodemail.calybre3d.com/mail/teklif",
        data
      );

      setTeklifGonderLoading(false);
      alert("Fiyat Teklifi Gönderildi");
      kaliteDialogRef.current.close();
      setOdemeLink("");
      setModalVeri(null);
      setTeklifKaliteFiyatToplam(0);
      setTeklifKaliteFiyat(0);
    } catch (error) {
      setTeklifGonderLoading(false);
      alert("Hata Olustu, tekrar deneyin.");
    }
  };

  const taramaHizmetiMailSend = async () => {
    setTeklifGonderLoading(true);
    const data = {
      toMail: eposta,
      headerData: "Tarama Modelleme Hizmeti",
      bodyData: `
      <!DOCTYPE html>
            <html lang="tr">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>RoboHive</title>
            </head>
            
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4;">
                <div
                    style="max-width: 800px; margin: 0 auto; padding: 20px; background-color: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);">
                    <div class="logo-size" style="text-align: center; align-items: center; margin-bottom: 40px;">
                        <img src="https://firebasestorage.googleapis.com/v0/b/robohive-9c678.appspot.com/o/weblogo.jpg?alt=media&token=5d7bd842-cfad-4dbb-a8d4-ca73b37e35cc"
                            height="80px" />
                    </div>
                    <div class="content-header" style="text-align: center; color: #333;">
                        <h1 style="margin: 0; font-size: 28px;">Fiyat Teklifi</h1>
                    </div>
                    <div class="paragraph-container" style="text-align: center; padding: 0 20px;">
                    
                    </div>
            
                    <div style="width: 100%; align-items: center; text-align: start;">
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px; "> Malzeme Çeşidi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.malzemeCesidi
                                    }</span></span>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px;"> Boyut Bilgisi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.boyutBilgisi
                                    }</span></span>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px;"> Termin Süresi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.teslimSuresi
                                    }</span></span>
                        </div>


                        <div style="margin-top: 10px;">
                        <span style="font-size: 13px;"> Tarih <span
                                style="color: rgb(158, 152, 152);">${moment(
                                  modalVeri.zaman
                                ).calendar()}</span></span>
                    </div>
            
                    </div>
                    <div
                        style="display: flex; justify-content: space-between; align-items: center;
                                        width: 100%;border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;margin-top: 30px;">
                        <span style="font-size: 18px;">Hizmet Bedeli</span>
                        <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">${teklifTaramaFiyat} TL</span>
                    </div>
                    <div class="price-details"
                        style="display: flex; width: 100%; justify-content: space-between; align-items: center; border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;">
                        <span style="font-size: 18px;">KDV</span>
                        <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">%20</span>
                    </div>
                    <div class="total" style="text-align: right; margin-top: 20px;">
                        <span style="font-size: 18px;">Toplam</span>
                        <span class="price"
                            style="font-size: 28px; font-weight: bold; color: #333;">${teklifTaramaFiyatToplam.toFixed(
                              2
                            )}TL</span>
                    </div>
                    <div style="width: 100%; text-align: center; justify-content: center;  align-items: center; ">
                        <div style="text-align: center; margin-top:30px;">
                        <a
                        href=${odemeLink}
                          style="background-color: forestgreen; display: inline-block; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; font-size: 16px; border-radius: 5px; cursor: pointer; ">Ödemeye
                          Geç</a>
                        </div>
                    </div>
                </div>
            </body>
            
            </html>
            `,
    };

    try {
      const response = await axios.post(
        "https://nodemail.calybre3d.com/mail/teklif",
        data
      );

      setTeklifGonderLoading(false);
      alert("Fiyat Teklifi Gönderildi");
      taramaDialogRef.current.close();
      setOdemeLink("");
      setModalVeri(null);
      document.getElementById(null);
      setTeklifTaramaFiyat(0);
      setTeklifTaramaFiyatToplam(0);
    } catch (error) {
      setTeklifGonderLoading(false);
      alert("Hata Oluştu, tekrar deneyiniz");
    }
  };
  if (taramaLoad || prototipLoad || kaliteLoad) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <>
      <div className="absolute w-full">
        <div className="navbar w-full flex justify-between fixed shadow-md text-center z-10 items-center bg-gray-50">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          </button>
          <h1>{isim}</h1>
          <div></div>
        </div>

        <div className="mt-16 px-6">
          <div className="max-w-7xl mx-auto py-10 space-y-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div>
                <h1 className="text-2xl font-semibold mb-2">
                  Kullanıcı Bilgileri
                </h1>
                <div className="bg-slate-100 border-2 rounded-2xl p-6 h-[360px] grid grid-cols-2 gap-4">
                  <div>
                    <label className="font-semibold">İsim Soyisim:</label>
                    <p className="text-gray-600 text-sm">{isim}</p>
                  </div>
                  <div>
                    <label className="font-semibold">E-posta:</label>
                    <p className="text-gray-600 text-sm">{eposta}</p>
                  </div>
                  <div>
                    <label className="font-semibold">Firma:</label>
                    <p className="text-gray-600 text-sm">{firmaIsmi}</p>
                  </div>
                  <div>
                    <label className="font-semibold">Telefon:</label>
                    <p className="text-gray-600 text-sm">{telefonNumarasi}</p>
                  </div>
                  <div>
                    <label className="font-semibold">Prototip Hizmeti:</label>
                    <span>
                      {prototipLoad ? (
                        <div
                          className=" top-0 left-0 w-5 h-5 border rounded-full border-blue-500 animate-spin"
                          style={{
                            borderTop: "2px solid transparent",
                            borderBottom: "2px solid transparent",
                          }}
                        ></div>
                      ) : (
                        <h1 className="text-gray-600">{prototipVal.length}</h1>
                      )}
                    </span>
                  </div>
                  <div>
                    <label className="font-semibold">Tarama Modelleme:</label>
                    <span>
                      {taramaLoad ? (
                        <div
                          className=" top-0 left-0 w-5 h-5 border rounded-full border-blue-500 animate-spin"
                          style={{
                            borderTop: "2px solid transparent",
                            borderBottom: "2px solid transparent",
                          }}
                        ></div>
                      ) : (
                        <h1 className="text-gray-600">{taramaVal.length}</h1>
                      )}
                    </span>
                  </div>
                  <div>
                    <label className="font-semibold">Kalite Kontrol:</label>
                    <span>
                      {kaliteLoad ? (
                        <div
                          className=" top-0 left-0 w-5 h-5 border rounded-full border-blue-500 animate-spin"
                          style={{
                            borderTop: "2px solid transparent",
                            borderBottom: "2px solid transparent",
                          }}
                        ></div>
                      ) : (
                        <h1 className="text-gray-600">{kaliteVal.length}</h1>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-semibold mb-2">E-posta Gönder</h1>

                <div className="bg-slate-100 border-2 rounded-2xl p-6 space-y-2">
                  <div className="flex flex-col">
                    <label className="ml-1">E-posta</label>
                    <input
                      type="email"
                      value={eposta}
                      disabled
                      className="p-2 border-2 rounded-xl text-gray-500 cursor-not-allowed"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">Konu</label>
                    <input
                      onChange={(e) => {
                        setEpostaKonu(e.target.value);
                      }}
                      value={epostaKonu}
                      type="text"
                      className="p-2 border-2 rounded-xl outline-none focus:border-purple-700"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">Mesaj</label>
                    <textarea
                      onChange={(e) => {
                        setEpostaMesaj(e.target.value);
                      }}
                      value={epostaMesaj}
                      type="text"
                      className="p-2 border-2 rounded-xl outline-none focus:border-purple-700"
                    ></textarea>
                  </div>
                  <div className="flex justify-center pt-4">
                    {epostaGonderLoading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mr-2 text-gray-200 animate-spin fill-primary"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Yukleniyor...</span>
                      </div>
                    ) : (
                      <button
                        onClick={async () => await mailGonder()}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-xl hover:bg-purple-700 duration-150"
                      >
                        Gönder
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-semibold mb-2">
                  WhatsApp Mesajı Gönder
                </h1>

                <div className="bg-slate-100 border-2 rounded-2xl p-6 space-y-2 h-[360px] flex flex-col justify-between">
                  <div className="space-y-2">
                    <div className="flex flex-col">
                      <label className="ml-1">Tel No:</label>
                      <input
                        type="tel"
                        value="+90 545 45 45"
                        disabled
                        className="p-2 border-2 rounded-xl text-gray-500 cursor-not-allowed"
                      />
                    </div>

                    <div className="flex flex-col h-full">
                      <label className="ml-1">Mesaj</label>
                      <textarea
                        type="text"
                        className="p-2 border-2 rounded-xl outline-none focus:border-purple-700 h-full"
                      ></textarea>
                    </div>
                  </div>

                  <div className="w-full flex justify-center pt-4">
                    <button
                      disabled
                      className="px-4 py-2 bg-gray-600 text-white rounded-xl  duration-150"
                    >
                      Gönder
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-semibold mb-2">Tarama Modelleme</h1>

              <div className="relative overflow-x-auto shadow-md sm:rounded-xl max-h-[569px]">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-indigo-600">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Malzeme Çeşidi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Ürün Boyutu
                      </th>
                      <th scope="col" className=" py-3">
                        Teslim Süresi (Gün)
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Yüklenme Tarihi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İncelenme Durumu
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 flex justify-end pr-24"
                      >
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {taramaVal &&
                      taramaVal
                        .map((item, itemIndex) => ({
                          id: itemIndex,
                          ...item,
                        }))
                        .sort((a, b) => {
                          // İncelenme durumuna göre sıralama yap
                          if (
                            a.incelenmeDurumu === false &&
                            b.incelenmeDurumu !== false
                          ) {
                            return -1; // a önce gelsin
                          }
                          if (
                            a.incelenmeDurumu !== false &&
                            b.incelenmeDurumu === false
                          ) {
                            return 1; // b önce gelsin
                          }
                          // İncelenme durumu aynı ise tarihe göre sırala
                        })
                        .map((e) => {
                          return (
                            <tr
                              key={e.id}
                              className="bg-white border-b hover:bg-gray-50"
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {e["malzemeCesidi"]}
                              </th>

                              <td className="px-6 py-4">{e["boyutBilgisi"]}</td>
                              <td className="px-6 py-4">{e["teslimSuresi"]}</td>
                              <td className="px-6 py-4">
                                {moment(e["zaman"]).calendar()}
                              </td>
                              <td className="px-6 py-4">
                                <span
                                  className={`inline-flex items-center gap-1 rounded-full ${
                                    e.incelenmeDurumu
                                      ? "bg-gray-50"
                                      : "bg-green-50"
                                  } px-2 py-1 text-xs font-semibold  ${
                                    e.incelenmeDurumu
                                      ? "text-gray-600"
                                      : "text-green-600"
                                  }`}
                                >
                                  <span
                                    className={`h-1.5 w-1.5 rounded-full ${
                                      e.incelenmeDurumu
                                        ? "bg-gray-600"
                                        : "bg-green-600"
                                    } `}
                                  ></span>
                                  {e.incelenmeDurumu
                                    ? "İncelendi"
                                    : "İncelenmedi"}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right space-x-4 flex items-center justify-end">
                                <h1
                                  onClick={async () => {
                                    setModalVeri({
                                      malzemeCesidi: await e.malzemeCesidi,
                                      boyutBilgisi: await e.boyutBilgisi,
                                      aciklama: await e.aciklama,
                                      teslimSuresi: await e.teslimSuresi,
                                      zaman: e.zaman,
                                      resimler: await e.dosyaLink,
                                    });
                                    taramaDialogRef.current.showModal();
                                  }}
                                  className="font-medium text-purple-600 hover:underline cursor-pointer"
                                >
                                  Teklif Gönder
                                </h1>
                                <button
                                  onClick={async () => {
                                    await taramaHizmetiIndir(
                                      isim,
                                      eposta,
                                      telefonNumarasi,
                                      firmaIsmi,
                                      e.dosyaLink[0],
                                      e.dosyaLink[1],
                                      e.dosyaLink[2],
                                      e.dosyaLink[3],
                                      firmaIsmi,
                                      e.malzemeCesidi,
                                      e.boyutBilgisi,
                                      e.aciklama,
                                      e.teslimSuresi
                                    );
                                  }}
                                  className="flex items-center space-x-1 bg-indigo-600 px-4 py-2 rounded-xl text-white font-semibold hover:bg-indigo-700"
                                >
                                  <h1>İndir</h1>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-semibold mb-2">Prototip Hizmeti</h1>

              <div className="relative overflow-x-auto shadow-md sm:rounded-xl max-h-[569px]">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-indigo-600">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Malzeme Çeşidi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Baski Teknolojisi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Ürün Boyutu
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Adet
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Yüklenme Tarihi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İncelenme Durumu
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 flex justify-end pr-24"
                      >
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {prototipVal &&
                      prototipVal
                        .map((item, itemIndex) => ({
                          id: itemIndex,
                          ...item,
                        }))
                        .sort((a, b) => {
                          // İncelenme durumuna göre sıralama yap
                          if (
                            a.incelenmeDurumu === false &&
                            b.incelenmeDurumu !== false
                          ) {
                            return -1; // a önce gelsin
                          }
                          if (
                            a.incelenmeDurumu !== false &&
                            b.incelenmeDurumu === false
                          ) {
                            return 1; // b önce gelsin
                          }
                          // İncelenme durumu aynı ise tarihe göre sırala
                        })
                        .map((e) => {
                          return (
                            <tr
                              key={e.id}
                              className="bg-white border-b hover:bg-gray-50"
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {e["malzemeCesidi"]}
                              </th>
                              <td className="px-6 py-4">
                                {e["baskiTeknolojisi"]}
                              </td>
                              <td className="px-6 py-4">{e["boyutBilgisi"]}</td>
                              <td className="px-6 py-4">{e["malzemeAdet"]}</td>

                              <td className="px-6 py-4">
                                {moment(e["zaman"]).calendar()}
                              </td>
                              <td className="px-6 py-4">
                                <span
                                  className={`inline-flex items-center gap-1 rounded-full ${
                                    e.incelenmeDurumu
                                      ? "bg-gray-50"
                                      : "bg-green-50"
                                  } px-2 py-1 text-xs font-semibold  ${
                                    e.incelenmeDurumu
                                      ? "text-gray-600"
                                      : "text-green-600"
                                  }`}
                                >
                                  <span
                                    className={`h-1.5 w-1.5 rounded-full ${
                                      e.incelenmeDurumu
                                        ? "bg-gray-600"
                                        : "bg-green-600"
                                    } `}
                                  ></span>
                                  {e.incelenmeDurumu
                                    ? "İncelendi"
                                    : "İncelenmedi"}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right space-x-4 flex items-center justify-end">
                                <h1
                                  className="font-medium text-purple-600 hover:underline cursor-pointer"
                                  onClick={async () => {
                                    setModalVeri({
                                      dosyaIsmi: await e.dosyaIsmi,
                                      malzemeCesidi: await e.malzemeCesidi,
                                      baskiTeknolojisi:
                                        await e.baskiTeknolojisi,
                                      malzemeAdet: await e.malzemeAdet,
                                      dosyaLink: await e.dosyaLink,
                                      zaman: await e.zaman,
                                    });

                                    prototipDialogRef.current.showModal();
                                  }}
                                >
                                  Teklif Gönder
                                </h1>
                                <button
                                  onClick={async () => {
                                    await prototipHizmetiIndir(
                                      isim,
                                      eposta,
                                      telefonNumarasi,
                                      firmaIsmi,
                                      e.dosyaLink,
                                      e.dosyaIsmi,
                                      e.boyutBilgisi,
                                      e.malzemeAdet,
                                      e.malzemeCesidi,
                                      e.baskiTeknolojisi
                                    );
                                  }}
                                  className="flex items-center space-x-1 bg-indigo-600 px-4 py-2 rounded-xl text-white font-semibold hover:bg-indigo-700"
                                >
                                  <h1>İndir</h1>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <h1 className="text-2xl font-semibold mb-2">Kalite Kontrol</h1>

              <div className="relative overflow-x-auto shadow-md sm:rounded-xl max-h-[569px]">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-indigo-600">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Olculecek Urun (Adet)
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Yüklenme Tarihi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İncelenme Durumu
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 flex justify-end pr-24"
                      >
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {kaliteVal &&
                      kaliteVal
                        .map((item, itemIndex) => ({
                          id: itemIndex,
                          ...item,
                        }))
                        .sort((a, b) => {
                          // İncelenme durumuna göre sıralama yap
                          if (
                            a.incelenmeDurumu === false &&
                            b.incelenmeDurumu !== false
                          ) {
                            return -1; // a önce gelsin
                          }
                          if (
                            a.incelenmeDurumu !== false &&
                            b.incelenmeDurumu === false
                          ) {
                            return 1; // b önce gelsin
                          }
                          // İncelenme durumu aynı ise tarihe göre sırala
                        })
                        .map((e) => {
                          return (
                            <tr
                              key={e.id}
                              className="bg-white border-b hover:bg-gray-50"
                            >
                              <td className="px-12 py-4">
                                {e["olculecekParcaSayisi"]}
                              </td>

                              <td className="px-6 py-4">
                                {moment(e["zaman"]).calendar()}
                              </td>
                              <td className="px-6 py-4">
                                <span
                                  className={`inline-flex items-center gap-1 rounded-full ${
                                    e.incelenmeDurumu
                                      ? "bg-gray-50"
                                      : "bg-green-50"
                                  } px-2 py-1 text-xs font-semibold  ${
                                    e.incelenmeDurumu
                                      ? "text-gray-600"
                                      : "text-green-600"
                                  }`}
                                >
                                  <span
                                    className={`h-1.5 w-1.5 rounded-full ${
                                      e.incelenmeDurumu
                                        ? "bg-gray-600"
                                        : "bg-green-600"
                                    } `}
                                  ></span>
                                  {e.incelenmeDurumu
                                    ? "İncelendi"
                                    : "İncelenmedi"}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right space-x-4 flex items-center justify-end">
                                <h1
                                  className="font-medium text-purple-600 hover:underline cursor-pointer"
                                  onClick={async () => {
                                    setModalVeri({
                                      dosyaIsmi: await e.dosyaIsmi,
                                      malzemeCesidi: await e.malzemeCesidi,
                                      baskiTeknolojisi:
                                        await e.baskiTeknolojisi,
                                      malzemeAdet: e.malzemeAdet,
                                      olculecekParcaSayisi:
                                        await e.olculecekParcaSayisi,
                                      resimler: await e.dosyaLink,
                                      zaman: await e.zaman,
                                    });

                                    kaliteDialogRef.current.showModal();
                                  }}
                                >
                                  Teklif Gönder
                                </h1>
                                <button
                                  onClick={async () => {
                                    await kaliteKontrolHizmetIndir(
                                      isim,
                                      eposta,
                                      telefonNumarasi,
                                      firmaIsmi,
                                      e.dosyaLink[0],
                                      e.dosyaLink[1],
                                      e.dosyaLink[2],
                                      e.dosyaLink[3],
                                      e.malzemeCesidi,
                                      e.olculecekParcaSayisi,
                                      e.teknikResim,
                                      e.cadData,
                                      e.cadDataIsim,
                                      e.teknikResimIsim
                                    );
                                  }}
                                  className="flex items-center space-x-1 bg-indigo-600 px-4 py-2 rounded-xl text-white font-semibold hover:bg-indigo-700"
                                >
                                  <h1>İndir</h1>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <dialog ref={taramaDialogRef} className="modal h-full ">
          {modalVeri && (
            <div className="modal-box w-11/12 max-w-5xl flex flex-col  ">
              <h3 className="font-bold text-lg">Teklif Gönder</h3>

              <div className="modal-action flex flex-col md:flex-row ">
                <div className="w-full h-full md:h-96 ">
                  <div className="grid grid-cols-2 gap-2 h-full w-full items-center">
                    {modalVeri.resimler != null ? (
                      modalVeri.resimler.map((resim, index) => (
                        <div
                          key={index}
                          className="w-full h-[188px] flex justify-center items-center"
                        >
                          <img
                            alt={`resim-${index}`}
                            className="h-[188px] w-full object-cover rounded-xl"
                            src={resim}
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="w-full  ">
                  <div className="flex h-full px-10">
                    <div className="flex flex-col w-full justify-evenly gap-2">
                      <div className="flex flex-row gap-1 ">
                        Malzeme Çeşidi:
                        <h1 className="font-medium text-gray-400">
                          {modalVeri.malzemeCesidi}
                        </h1>
                      </div>
                      <div className="flex flex-row gap-1 ">
                        Boyut Bilgisi:
                        <h1 className="font-medium text-gray-400">
                          {modalVeri.boyutBilgisi}
                        </h1>
                      </div>
                      <h1>Müşteri Açıklaması:</h1>
                      <div className="flex flex-row gap-1 ">
                        <div className="overflow-auto w-full h-16 outline outline-1 rounded-sm outline-offset-2 outline-gray-300">
                          <h1 className="font-medium text-gray-500 max-w-xs break-words ">
                            {modalVeri.aciklama}
                          </h1>
                        </div>
                      </div>
                      <div className="flex flex-row gap-1 ">
                        Fiyat:
                        <h1 className="font-medium">{teklifTaramaFiyat} TL</h1>
                      </div>
                      <div className="flex flex-row gap-1">
                        KDV: <h1 className="font-medium"> %20 </h1>
                      </div>
                      <div className="flex flex-row gap-1">
                        Toplam Fiyat :
                        <h1 className="font-bold">
                          {teklifTaramaFiyatToplam} TL
                        </h1>
                      </div>
                      <input
                        onChange={(e) => setOdemeLink(e.target.value)}
                        value={odemeLink}
                        type="text"
                        placeholder="Ödeme Linki"
                        className="input input-bordered w-full max-w-xs "
                      />
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-col text-black py-2 ">
                          <label> Fiyat:</label>
                          <div>
                            <input
                              onChange={taramaHizmetHesap}
                              value={teklifTaramaFiyat}
                              type="number"
                              className="rounded-lg input input-bordered mt-2 p-2 w-24 text-xl h-16 focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                          </div>
                        </div>

                        <div className="h-full items-center flex pt-7">
                          <select className="select select-bordered w-40 max-w-xs">
                            <option disabled selected>
                              Yöntem
                            </option>
                            <option>Eposta</option>
                            <option>WhatsApp</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-end gap-4 pt-2">
                {teklifGonderLoading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mr-2 text-gray-200 animate-spin fill-primary"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Yukleniyor...</span>
                  </div>
                ) : (
                  <>
                    <div className="text-end ">
                      <button
                        onClick={async () => {
                          try {
                            await taramaHizmetiMailSend();

                            // Kullanıcı kimliğini burada ayarla
                            const zaman = modalVeri.zaman; // modalVeri.zaman'ın doğru bir değer olduğundan emin ol

                            // İsim alanına göre sorgu yap
                            const q = query(
                              collection(
                                getFirestore(FirebaseApp),
                                `USERS/${userId}/taramaModelleme`
                              ),
                              where("zaman", "==", zaman)
                            );

                            // Sorguyu çalıştır ve belgeleri al
                            const querySnapshot = await getDocs(q);

                            // Her belgeyi güncelle
                            querySnapshot.forEach(
                              async (queryDocumentSnapshot) => {
                                const docRef = doc(
                                  getFirestore(FirebaseApp),
                                  `USERS/${userId}/taramaModelleme/${queryDocumentSnapshot.id}`
                                );

                                // Dönüştürülen referansı kullanarak belgeyi güncelle
                                await updateDoc(docRef, {
                                  incelenmeDurumu: true,
                                });
                              }
                            );
                          } catch (error) {
                            alert("Hata Oluştu, tekrar deneyiniz");
                          }
                        }}
                        className="btn h-8 w-20 text-white hover:bg-blue-700 bg-blue-500"
                      >
                        Gönder
                      </button>
                    </div>
                    <div className="text-end">
                      <button
                        onClick={() => {
                          taramaDialogRef.current.close();
                        }}
                        className="btn h-8 w-20 "
                      >
                        İptal
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </dialog>
      </>

      <>
        <dialog ref={kaliteDialogRef} className="modal h-full">
          {modalVeri && (
            <div className="modal-box w-11/12 max-w-5xl flex flex-col">
              <h3 className="font-bold text-lg">Teklif Gönder</h3>

              <div className="modal-action flex-auto flex flex-col md:flex-row">
                <div className="w-full h-full md:h-96  ">
                  <div className="grid grid-cols-2 gap-2 h-full w-full items-center">
                    {modalVeri.resimler != null ? (
                      modalVeri.resimler.map((resim, index) => (
                        <div
                          key={index}
                          className="w-full h-[188px] flex justify-center items-center"
                        >
                          <img
                            alt={`resim-${index}`}
                            className="h-[188px] w-full object-cover rounded-xl"
                            src={resim}
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="w-full h-1/3 md:h-96">
                  <div className="flex h-full px-10">
                    <div className="flex flex-col w-full justify-evenly">
                      <div className="flex flex-row gap-1 ">
                        Malzeme Çeşidi:
                        <h1 className="font-medium text-gray-400">
                          {modalVeri.malzemeCesidi}
                        </h1>
                      </div>

                      <div className="flex flex-row gap-1 ">
                        Olculecek Parca:
                        <h1 className="font-medium text-gray-400">
                          {modalVeri.olculecekParcaSayisi}
                        </h1>
                      </div>

                      <div className="flex flex-row gap-1 ">
                        Fiyat:
                        <h1 className="font-medium">{teklifTaramaFiyat} TL</h1>
                      </div>

                      <div className="flex flex-row gap-1">
                        KDV: <h1 className="font-medium"> %20 </h1>
                      </div>

                      <div className="flex flex-row gap-1">
                        Toplam Fiyat :
                        <h1 className="font-bold">
                          {teklifKaliteFiyatToplam} TL
                        </h1>
                      </div>
                      <input
                        onChange={(e) => setOdemeLink(e.target.value)}
                        value={odemeLink}
                        type="text"
                        placeholder="Ödeme Linki"
                        className="input input-bordered w-full max-w-xs"
                      />
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-col text-black py-2 ">
                          <label> Fiyat:</label>
                          <div>
                            <input
                              onChange={kaliteHizmetHesap}
                              value={teklifKaliteFiyat}
                              type="number"
                              className="rounded-lg input input-bordered mt-2 p-2 w-24 text-xl h-16 focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                          </div>
                        </div>

                        <div className="h-full items-center flex pt-7">
                          <select className="select select-bordered w-40 max-w-xs">
                            <option disabled selected>
                              Yöntem
                            </option>
                            <option>Eposta</option>
                            <option>WhatsApp</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-end gap-4">
                {teklifGonderLoading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mr-2 text-gray-200 animate-spin fill-primary"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <div className="text-end">
                      <button
                        onClick={async () => {
                          try {
                            await kaliteKontrolMailSend();

                            // Kullanıcı kimliğini burada ayarla
                            const zaman = modalVeri.zaman; // modalVeri.zaman'ın doğru bir değer olduğundan emin ol

                            // İsim alanına göre sorgu yap
                            const q = query(
                              collection(
                                getFirestore(FirebaseApp),
                                `USERS/${userId}/kaliteKontrol`
                              ),
                              where("zaman", "==", zaman)
                            );

                            // Sorguyu çalıştır ve belgeleri al
                            const querySnapshot = await getDocs(q);

                            // Her belgeyi güncelle
                            querySnapshot.forEach(
                              async (queryDocumentSnapshot) => {
                                const docRef = doc(
                                  getFirestore(FirebaseApp),
                                  `USERS/${userId}/kaliteKontrol/${queryDocumentSnapshot.id}`
                                );

                                // Dönüştürülen referansı kullanarak belgeyi güncelle
                                await updateDoc(docRef, {
                                  incelenmeDurumu: true,
                                });
                              }
                            );
                          } catch (error) {
                            alert("Hata Oluştu, tekrar deneyiniz");
                          }
                        }}
                        className="btn h-8 w-20 text-white hover:bg-blue-700 bg-blue-500"
                      >
                        Gönder
                      </button>
                    </div>
                    <div className="text-end">
                      <button
                        onClick={() => {
                          kaliteDialogRef.current.close();
                        }}
                        className="btn h-8 w-20 "
                      >
                        İptal
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </dialog>
      </>

      <>
        <dialog ref={prototipDialogRef} className="modal h-full">
          {modalVeri && (
            <div className="modal-box w-11/12 max-w-5xl flex flex-col">
              <h3 className="font-bold text-lg">Teklif Gönder</h3>

              <div className="modal-action flex-auto flex flex-col md:flex-row">
                <div className={`w-full h-40 md:h-96 `}>
                  <StlViewer
                    className="h-40 md:h-96"
                    floorProps={{
                      gridWidth: 300,
                    }}
                    modelProps={{
                      positionX: 150,
                      positionY: 150,
                      ref: {
                        current: "[Circular]",
                      },
                      rotationX: 0,
                      rotationY: 0,
                      rotationZ: 0,
                      scale: 1,
                    }}
                    onError={function noRefCheck() {}}
                    onFinishLoading={() => {
                      setStlFinish(true);
                    }}
                    orbitControls
                    shadows
                    showAxes
                    url={modalVeri.dosyaLink}
                  />
                </div>

                <div
                  className={`${
                    stlFinish
                      ? "hidden"
                      : "absolute left-1/4 top-1/2 items-center"
                  }`}
                >
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mr-2 text-gray-200 animate-spin fill-primary"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>

                <div className="w-full h-1/3 md:h-96">
                  <div className="flex h-full px-10">
                    <div className="flex flex-col w-full justify-evenly">
                      <div className="flex flex-row gap-1 ">
                        Dosya İsmi:
                        <h1 className="font-medium"> {modalVeri.dosyaIsmi} </h1>
                      </div>
                      <div className="flex flex-row gap-1 ">
                        Malzeme Çeşidi:
                        <h1 className="font-medium">
                          {modalVeri.malzemeCesidi}{" "}
                        </h1>
                      </div>
                      <div className="flex flex-row gap-1 ">
                        Baski Teknolojisi:
                        <h1 className="font-medium">
                          {modalVeri.baskiTeknolojisi}
                        </h1>
                      </div>
                      <div className="flex flex-row gap-1 ">
                        Adet:
                        <h1 className="font-medium">{modalVeri.malzemeAdet}</h1>
                      </div>
                      <div className="flex flex-row gap-1 ">
                        Fiyat:
                        <h1 className="font-medium">
                          {teklifPrototipFiyat} TL
                        </h1>
                      </div>

                      <div className="flex flex-row gap-1">
                        KDV: <h1 className="font-medium"> %20 </h1>
                      </div>

                      <div className="flex flex-row gap-1">
                        Toplam Fiyat :
                        <h1 className="font-bold">
                          {teklifPrototipFiyatToplam} TL
                        </h1>
                      </div>
                      <input
                        onChange={(e) => setOdemeLink(e.target.value)}
                        value={odemeLink}
                        type="text"
                        placeholder="Ödeme Linki"
                        className="input input-bordered w-full max-w-xs"
                      />
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-col text-black py-2 ">
                          <label> Fiyat:</label>
                          <div>
                            <input
                              onChange={prototipHizmetiHesap}
                              value={teklifPrototipFiyat}
                              type="number"
                              className="rounded-lg input input-bordered mt-2 p-2 w-24 text-xl h-16 focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                          </div>
                        </div>

                        <div className="h-full items-center flex pt-7">
                          <select className="select select-bordered w-40 max-w-xs">
                            <option disabled selected>
                              Yöntem
                            </option>
                            <option>Eposta</option>
                            <option>WhatsApp</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-end gap-4">
                {teklifGonderLoading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mr-2 text-gray-200 animate-spin fill-primary"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <div className="text-end">
                      <button
                        onClick={async () => {
                          try {
                            await prototipHizmetiMailSend();

                            // Kullanıcı kimliğini burada ayarla
                            const zaman = modalVeri.zaman; // modalVeri.zaman'ın doğru bir değer olduğundan emin ol

                            // İsim alanına göre sorgu yap
                            const q = query(
                              collection(
                                getFirestore(FirebaseApp),
                                `USERS/${userId}/prototipHizmeti`
                              ),
                              where("zaman", "==", zaman)
                            );

                            // Sorguyu çalıştır ve belgeleri al
                            const querySnapshot = await getDocs(q);

                            // Her belgeyi güncelle
                            querySnapshot.forEach(
                              async (queryDocumentSnapshot) => {
                                const docRef = doc(
                                  getFirestore(FirebaseApp),
                                  `USERS/${userId}/prototipHizmeti/${queryDocumentSnapshot.id}`
                                );

                                // Dönüştürülen referansı kullanarak belgeyi güncelle
                                await updateDoc(docRef, {
                                  incelenmeDurumu: true,
                                });
                              }
                            );
                          } catch (error) {
                            alert("Hata Oluştu, tekrar deneyiniz");
                          }
                        }}
                        className="btn h-8 w-20 text-white hover:bg-blue-700 bg-blue-500"
                      >
                        Gönder
                      </button>
                    </div>
                    <div className="text-end">
                      <button
                        onClick={() => {
                          prototipDialogRef.current.close();
                        }}
                        className="btn h-8 w-20 "
                      >
                        İptal
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </dialog>
      </>
    </>
  );
}

export default CustomerDetail;

/*

    <div className="text-end">
                  <button className="btn h-8 w-20 text-white hover:bg-blue-700 bg-blue-500">
                    Gönder
                  </button>
                </div>
                <div className="text-end">
                  <button className="btn h-8 w-20 ">İptal</button>
                </div>

*/

/*


    <div className="text-end">
                  <button className="btn h-8 w-20 text-white hover:bg-blue-700 bg-blue-500">
                    Gönder
                  </button>
                </div>
                <div className="text-end">
                  <button className="btn h-8 w-20 ">İptal</button>
                </div>


                



/*




      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col justify-center items-center">
          <div className="w-full md:max-w-4xl px-6">
            <div className="flex justify-end">
              <svg
                onClick={() => setIsModalOpen(false)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-8 h-8 text-gray-500 mb-2 cursor-pointer hover:text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="w-full bg-white h-[500px] rounded-2xl grid grid-cols-1 lg:grid-cols-2 gap-6 p-6">
              <div className="flex flex-col justify-center items-center border-b-2 lg:border-b-0 lg:border-r-2">
                <h1>Three.js</h1>
              </div>
              <div className="flex flex-col justify-center space-y-12">
                <div className="flex items-center justify-between text-2xl font-semibold">
                  <h1>Anlık Fiyat:</h1>
                  <h1>Fiyat</h1>
                </div>
                <div className="flex items-center justify-between text-2xl font-semibold">
                  <h1>KDV:</h1>
                  <h1>Fiyat</h1>
                </div>
                <div className="flex items-center justify-between text-2xl font-semibold">
                  <div className="flex flex-col">
                    <label>Fiyat:</label>
                    <input
                      type="text"
                      className="w-32 border-2 border-gray-500 rounded-lg p-2 text-base font-normal outline-none focus:border-purple-600"
                    />
                  </div>
                  <h1 className="font-normal text-base">Toplam Fiyat: 20</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}



      */

import React, { useState } from "react";
import { useRecoilState } from "recoil";
import {
  PrototipModalData,
  PrototipModalState,
} from "../../../state/SideBarState";
import { StlViewer } from "react-stl-viewer";
import axios from "axios";
import { prototipHizmetiIndir } from "./DownloadFunc";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { FirebaseApp } from "../../../FirebaseConfig";
import {
  Firestore,
  doc,
  getDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
const fireDb = getFirestore(FirebaseApp);
export default function Modal() {
  const [modalOpen, setModelOpen] = useRecoilState(PrototipModalState);
  const [modalVeri, modalSetVeri] = useRecoilState(PrototipModalData);
  let [stlLoading, setStlLoading] = useState(true);
  let [teklifGonderLoading, setTeklifGonderLoading] = useState(false);
  let [teklifPrototipFiyat, setTeklifPrototipFiyat] = useState(0);
  let [teklifPrototipFiyatKdv, setTeklifPrototipFiyatKdv] = useState(0);

  const modalClose = () => {
    setModelOpen(false);
  };

  const style = {
    width: "400px",
    height: "45vh",
  };

  const prototipHizmetiHesap = (e) => {
    const fiyat = parseFloat(e.target.value); // Girilen değeri sayıya dönüştür
    setTeklifPrototipFiyat(fiyat); // Dönüştürülmüş değeri state'e atar
    const kdvHesap = (fiyat * 20) / 100;
    const kdvToplamHesap = fiyat + kdvHesap;
    setTeklifPrototipFiyatKdv(kdvToplamHesap);
  };

  const prototipHizmetiMailSend = async (e) => {
    setTeklifGonderLoading(true);
    const data = {
      toMail: "mertkorayates200@gmail.com",
      headerData: "Prototip Hizmeti",
      bodyData: `<!DOCTYPE html>
            <html lang="tr">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>RoboHive</title>
            </head>
            
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4;">
                <div
                    style="max-width: 800px; margin: 0 auto; padding: 20px; background-color: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);">
                    <div class="logo-size" style="text-align: center; align-items: center; margin-bottom: 40px;">
                        <img src="https://firebasestorage.googleapis.com/v0/b/robohive-9c678.appspot.com/o/weblogo.png?alt=media&token=9a21deb7-c2fd-49ff-9fed-84758bc16937"
                            height="80px" />
                    </div>
                    <div class="content-header" style="text-align: center; color: #333;">
                        <h1 style="margin: 0; font-size: 28px;">Fiyat Teklifi</h1>
                    </div>
                    <div class="paragraph-container" style="text-align: center; padding: 0 20px;">
                        <p class="paragraph" style="display: inline-block; text-align: left; max-width: 100%;">
                            <span style="display: inline-block; white-space: nowrap; font-size: 15px;">Prototipleme Hizmeti :<span
                                    style="color: rgb(158, 152, 152);"> ${
                                      modalVeri.product.dosyaIsmi
                                    }</span></span>
                        </p>
                    </div>
            
                    <div style="width: 100%; align-items: center; text-align: start;">
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px; "> Malzeme Çeşidi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.product.malzemeCesidi
                                    }</span></span>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px;"> Baskı Teknolojisi <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.product.baskiTeknolojisi
                                    }</span></span>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="font-size: 13px;"> Adet <span
                                    style="color: rgb(158, 152, 152);">${
                                      modalVeri.product.malzemeAdet
                                    }</span></span>
                        </div>
            
                    </div>
                    <div
                        style="display: flex; justify-content: space-between; align-items: center;
                                        width: 100%;border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;margin-top: 30px;">
                        <span style="font-size: 18px;">Hizmet Bedeli</span>
                        <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">${teklifPrototipFiyat} TL</span>
                    </div>
                    <div class="price-details"
                        style="display: flex; width: 100%; justify-content: space-between; align-items: center; border-bottom: 1px solid #ddd; padding: 10px 0; margin-bottom: 20px;">
                        <span style="font-size: 18px;">KDV</span>
                        <span class="price" style="font-size: 24px; font-weight: bold; color: #333;">%20</span>
                    </div>
                    <div class="total" style="text-align: right; margin-top: 20px;">
                        <span style="font-size: 18px;">Toplam</span>
                        <span class="price"
                            style="font-size: 28px; font-weight: bold; color: #333;">${teklifPrototipFiyatKdv.toFixed(
                              2
                            )}TL</span>
                    </div>
                    <div style="width: 100%; text-align: center; justify-content: center;  align-items: center; ">
                        <div style="text-align: center; margin-top:30px;">
                            <button
                                style="background-color: forestgreen; display: inline-block; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; font-size: 16px; border-radius: 5px; cursor: pointer; ">Ödemeye
                                Geç</button>
                        </div>
                    </div>
                </div>
            </body>
            
            </html>
            `,
    };

    try {
      const response = await axios.post(
        "http://127.0.0.1:9000/mail/teklif",
        data
      );
      console.log("Response:", response.data);
      setTeklifGonderLoading(false);
      modalClose();
    } catch (error) {
      setTeklifGonderLoading(false);
      console.error("Error:", error);
    }
  };

  const onStlFinish = () => {
    console.log("bitti");
    setStlLoading(false);
  };
  console.log(modalVeri);
  if (modalVeri.id === 0) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="relative w-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700"
        >
          <div className="relative w-full max-w-4xl h-full  max-h-[80vh]">
            <div className="relative bg-gray-100 rounded-lg shadow">
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900">
                  Teklif Gönder
                </h3>

                <button
                  type="button"
                  onClick={modalClose}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="flex w-screen">
                <div className="flex">
                  <StlViewer
                    style={style}
                    floorProps={{
                      gridWidth: 300,
                    }}
                    modelProps={{
                      positionX: 150,
                      positionY: 150,
                      ref: {
                        current: "[Circular]",
                      },
                      rotationX: 0,
                      rotationY: 0,
                      rotationZ: 0,
                      scale: 1,
                    }}
                    onError={function noRefCheck() {}}
                    onFinishLoading={onStlFinish}
                    onLoad={function test() {
                      console.log("yukleniyor..");
                    }}
                    orbitControls
                    shadows
                    showAxes
                    url={modalVeri.product.dosyaLink}
                  />
                </div>

                <div className=" block mx-20 w-[830px] ">
                  <div className="m-5">
                    <h1 className="text-gray-600">
                      Malzeme Çeşidi:{" "}
                      <span className="text-gray-400">
                        {modalVeri.product.malzemeCesidi}
                      </span>
                    </h1>
                  </div>
                  <div className="m-5">
                    <h1 className="text-gray-600">
                      Baskı Teknolojisi:{" "}
                      <span className="text-gray-400">
                        {modalVeri.product.baskiTeknolojisi}
                      </span>
                    </h1>
                  </div>
                  <div className="m-5">
                    <h1 className="text-gray-600">
                      Adet:{" "}
                      <span className="text-gray-400">
                        {modalVeri.product.malzemeAdet}
                      </span>
                    </h1>
                  </div>

                  <div className="flex flex-col text-bllack py-2 px-5">
                    <label> Fiyat:</label>
                    <div>
                      <input
                        type="number"
                        value={teklifPrototipFiyat}
                        onChange={prototipHizmetiHesap}
                        className="rounded-lg bg-gray-300  mt-2 p-2 w-24 text-xl h-16 focus:border-blue-500 focus:bg-white focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="relative w-full left-60 text-gray-600">
                    <div className="text-sm">
                      <h1 className="font-bold">
                        Fiyat:{" "}
                        <span className="font-medium">
                          {teklifPrototipFiyat} TL
                        </span>
                      </h1>
                    </div>
                    <div className="text-sm">
                      <h1 className="font-bold">
                        KDV: <span className="font-medium">%20</span>
                      </h1>
                    </div>
                    <div className="text-lgs">
                      <h1 className="font-bold">
                        Toplam:{" "}
                        <span className="font-bold">
                          {teklifPrototipFiyatKdv.toFixed(2)} TL
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                {teklifGonderLoading === false ? (
                  <>
                    {" "}
                    <button
                      onClick={prototipHizmetiMailSend}
                      type="button"
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:outline-none rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 "
                    >
                      Gönder
                    </button>
                    <button
                      onClick={modalClose}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 "
                    >
                      İptal
                    </button>
                  </>
                ) : (
                  <h1>Gonderiliyor...</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (modalVeri.id === 1) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="relative w-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700"
        >
          <div className="relative w-full max-w-4xl h-full  max-h-[80vh]">
            <div className="relative bg-gray-100 rounded-lg shadow">
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900">Mesaj</h3>
                <button
                  type="button"
                  onClick={modalClose}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  With less than a month to go before the European Union enacts
                  new consumer privacy laws for its citizens, companies around
                  the world are updating their terms of service agreements to
                  comply.
                </p>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  The European Union’s General Data Protection Regulation
                  (G.D.P.R.) goes into effect on May 25 and is meant to ensure a
                  common set of data rights in the European Union. It requires
                  organizations to notify users as soon as possible of high-risk
                  data breaches that could personally affect them.
                </p>
              </div>

              <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={modalClose}
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700"
                >
                  I accept
                </button>
                <button
                  onClick={modalClose}
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:outline-nonerounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="relative w-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700"
        >
          <div className="relative w-full max-w-4xl h-full  max-h-[80vh]">
            <div className="relative bg-gray-100 rounded-lg shadow">
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900">
                  Dosyaları İndir
                </h3>
                <button
                  type="button"
                  onClick={modalClose}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="p-20 space-y-6 justify-center text-center block w-full">
                <AiOutlineCloudDownload className="w-40 h-40 text-gray-400 mx-auto"></AiOutlineCloudDownload>
                <p>{modalVeri.product.dosyaIsmi}</p>
              </div>

              <div className="flex w-full justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={async () => {
                    await prototipHizmetiIndir(
                      modalVeri.isim,
                      modalVeri.eposta,
                      modalVeri.telefonNumarasi,
                      modalVeri.firmaIsmi,
                      modalVeri.product.dosyaLink,
                      modalVeri.product.dosyaIsmi,
                      modalVeri.product.boyutBilgisi,
                      modalVeri.product.malzemeAdet,
                      modalVeri.product.malzemeCesidi,
                      modalVeri.product.baskiTeknolojisi
                    );
                  }}
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700"
                >
                  İndir
                </button>
                <button
                  onClick={modalClose}
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:outline-none rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-60"
                >
                  İptal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*

  <div className='flex'>
                                    <StlViewer

                                        style={style}
                                        floorProps={{
                                            gridWidth: 300
                                        }}
                                        modelProps={{


                                            positionX: 150,
                                            positionY: 150,
                                            ref: {
                                                current: '[Circular]'
                                            },
                                            rotationX: 0,
                                            rotationY: 0,
                                            rotationZ: 0,
                                            scale: 1
                                        }}
                                        onError={function noRefCheck() { }}

                                        onFinishLoading={onStlFinish}
                                        orbitControls
                                        shadows
                                        showAxes
                                        url={modalVeri.product.dosyaLink}
                                    />

                                </div>


*/

/*

                           <StlViewer
                                                className={`h-[50vh] sm:w-60vw md:w-40vw mx-5 `}
                                                floorProps={{
                                                    gridWidth: 300
                                                }}
                                                modelProps={{


                                                    positionX: 150,
                                                    positionY: 150,
                                                    ref: {
                                                        current: '[Circular]'
                                                    },
                                                    rotationX: 0,
                                                    rotationY: 0,
                                                    rotationZ: 0,
                                                    scale: 1
                                                }}
                                                onError={function noRefCheck() { }}
                                                onFinishLoading={onStlLoaded}
                                                orbitControls
                                                shadows
                                                showAxes
                                                url={stlFile}
                                            />

*/

import React, { useState, useEffect } from "react";
import { FirebaseApp } from "./FirebaseConfig";
import Logo from "./assets/weblogo.svg";
import {
  collection,
  getFirestore,
  query,
  orderBy,
  limit,
  startAfter,
  doc,
} from "firebase/firestore";
import usePagination from "react-firebase-pagination";
import { useCollection } from "react-firebase-hooks/firestore";
const mainQuery = query(
  collection(getFirestore(FirebaseApp), "USERS"),
  orderBy("mesaj", "desc")
);
function TestPage() {
  let colorChange = false;
  const [value, fireLoading, error] = useCollection(
    collection(getFirestore(FirebaseApp), "USERS"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const { getNext, getPrevious, data, loading } = usePagination({
    pageSize: 4,
    pageByPage: true,
    liveUpdate: true,
    query: mainQuery,
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="absolute inset-x-10">
      <div className="navbar w-full flex justify-center fixed shadow-md text-center items-center bg-gray-50">
        <img src={Logo} width={30}></img>
      </div>
      <div className="absolute -z-10 mt-12 w-full inset-x-10 inset-y-10">
        <h1 className="text-3xl font-thin">Cihazlarım</h1>
        <div className="w-full text-center">
          <div className="overflow-x-auto">
            <table className="table items-center justify-center ">
              <thead>
                <tr>
                  <th></th>
                  <th>Cihaz Ismi</th>
                  <th>Cihaz ID</th>
                  <th>Kayit Zamanı</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="fixed w-full text-center">
                    <span className="loading  loading-spinner loading-lg"></span>
                  </div>
                ) : (
                  data.docs.map((data, index) => {
                    colorChange = !colorChange;

                    return (
                      <tr
                        key={index}
                        className={`${
                          colorChange ? "bg-base-200" : "bg-base-100"
                        } h-20 cursor-pointer`}
                      >
                        <th>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
                            />
                          </svg>
                        </th>
                        <td>aaa</td>
                        <td>aaa</td>
                        <td>aaa</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestPage;

/*




import React, { useState, useEffect } from "react";
import { FirebaseApp } from "./FirebaseConfig";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

function TestPage() {
  const pageSize = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const [lastDoc, setLastDoc] = useState(null);

  const [value, loading, error] = useCollection(
    query(
      collection(getFirestore(FirebaseApp), "USERS"),
      orderBy("mesaj", "desc"),
      limit(pageSize),
      lastDoc != null ? startAfter(lastDoc) : startAfter(0)
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    console.log("Current Page Changed:", currentPage);
  }, [currentPage]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      {value.docs.map((doc) => (
        <div key={doc.id}>{doc.id}</div>
      ))}
      <div className="gap-10">
        <button
          onClick={() => {
            setCurrentPage(currentPage - 1);
            setLastDoc(value.docs[value.docs.length]);
          }}
          disabled={currentPage === 1} // İlk sayfadaysa önceki sayfa butonunu devre dışı bırak
        >
          Önceki Sayfa
        </button>
        <button
          onClick={() => {
            setCurrentPage(currentPage + 1);
            console.log(
              `GELEN LENGTH DEGERI = ${value.docs[value.docs.length]}`
            );
            setLastDoc(value.docs[value.docs.length - 1]);
          }}
          disabled={value.docs.length < pageSize} // Sayfa dolu değilse sonraki sayfa butonunu devre dışı bırak
        >
          Sonraki Sayfa
        </button>
      </div>
    </div>
  );
}

export default TestPage;




*/

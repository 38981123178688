import React, { useState, useEffect } from "react";
import Logo from "../../../assets/weblogo.svg";
import {
  collection,
  getFirestore,
  query,
  orderBy,
  doc,
  updateDoc,
} from "firebase/firestore";
import usePagination from "react-firebase-pagination";

import { FirebaseApp } from "../../../FirebaseConfig";
import { useNavigate } from "react-router-dom";
const mainQuery = query(
  collection(getFirestore(FirebaseApp), "USERS"),
  orderBy("mesaj", "desc")
);
function GelenKutusu() {
  const [pageSize, setPageSize] = useState(1);
  let navigate = useNavigate();
  let colorChange = false;
  const { getNext, getPrevious, data, loading, error } = usePagination({
    pageSize: 8,
    pageByPage: true,
    liveUpdate: true,
    query: mainQuery,
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Hata Olustu !</p>;
  }

  return (
    <div className="absolute inset-x-10">
      <div className="navbar w-full flex justify-center fixed shadow-md text-center items-center bg-gray-50">
        <img alt="logo" src={Logo} width={60}></img>
      </div>

      <div className="absolute h-screen -z-10 mt-12 w-full inset-x-10 inset-y-10">
        <div className="flex justify-between">
          <h1 className="text-3xl font-thin">Müşteriler</h1>
          <div className="flex px-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
            <h1>{loading ? "..." : data.totalDocs}</h1>
          </div>
        </div>
        <div className="w-full text-center">
          <div className="overflow-x-auto">
            <table className="table items-center justify-center ">
              <thead>
                <tr>
                  <th></th>
                  <th>Isim</th>
                  <th>Firma</th>
                  <th>Telefon</th>
                  <th>Durum</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="fixed w-full text-center">
                    <span className="loading  loading-spinner loading-lg"></span>
                  </div>
                ) : data && data.docs ? (
                  data.docs.map((data, index) => {
                    colorChange = !colorChange;
                    return (
                      <tr
                        onClick={async () => {
                          const docRef = doc(
                            getFirestore(FirebaseApp),
                            `USERS/${data.id}`
                          );

                          await updateDoc(docRef, { mesaj: false });

                          navigate("/detay", {
                            state: {
                              eposta: data.data()["eposta"],
                              isim: `${
                                data.data()["isim"] +
                                " " +
                                data.data()["soyisim"]
                              }`,
                              userId: data.id,
                              firmaIsmi: data.data()["firma"],
                              telefon: data.data()["telefon"],
                            },
                          });
                        }}
                        key={data.id}
                        className={`${
                          colorChange ? "bg-base-200" : "bg-base-100"
                        } h-20 cursor-pointer`}
                      >
                        <th>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                            />
                          </svg>
                        </th>
                        <td>{data.data()["isim"]}</td>
                        <td>{data.data()["firma"]}</td>
                        <td>{data.data()["telefon"]}</td>
                        <td>
                          <h1
                            className={`${
                              data.data()["mesaj"]
                                ? "text-green-500"
                                : "text-gray-500"
                            }`}
                          >{`${
                            data.data()["mesaj"] ? "Yeni Mesaj" : "İncelendi"
                          }`}</h1>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="fixed w-full text-center">
                    <span className="loading  loading-spinner loading-lg"></span>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end gap-2 px-2 py-2">
          <button
            onClick={() => {
              setPageSize(pageSize - 1);

              getPrevious();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <h1 className="text-lg p-1.5 text-gray-500 rounded-full">
            {pageSize}
          </h1>

          <button
            onClick={() => {
              getNext();
              setPageSize(pageSize + 1);
            }}
            disabled={false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default GelenKutusu;

/*



  <div className="absolute inset-x-10">
      <div className="navbar w-full flex justify-center fixed shadow-md text-center items-center bg-gray-50">
        <img src={Logo} width={30}></img>
      </div>

      <div className="absolute h-screen -z-10 mt-12 w-full inset-x-10 inset-y-10">
        <h1 className="text-3xl font-thin">Cihazlarım</h1>
        <div className="w-full text-center">
          <div className="overflow-x-auto">
            <table className="table items-center justify-center ">
              <thead>
                <tr>
                  <th></th>
                  <th>Cihaz Ismi</th>
                  <th>Cihaz ID</th>
                  <th>Kayit Zamanı</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="fixed w-full text-center">
                    <span className="loading  loading-spinner loading-lg"></span>
                  </div>
                ) : (
                  data.docs.map((data, index) => {
                    colorChange = !colorChange;
                    return (
                      <tr
                        key={index}
                        className={`${
                          colorChange ? "bg-base-200" : "bg-base-100"
                        } h-20 cursor-pointer`}
                      >
                        <th>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
                            />
                          </svg>
                        </th>
                        <td>asasasasasasas</td>
                        <td>asasasassa</td>
                        <td>aasaa</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
*/

/*




import React, { useState, useEffect } from "react";
import { FirebaseApp } from "./FirebaseConfig";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

function TestPage() {
  const pageSize = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const [lastDoc, setLastDoc] = useState(null);

  const [value, loading, error] = useCollection(
    query(
      collection(getFirestore(FirebaseApp), "USERS"),
      orderBy("mesaj", "desc"),
      limit(pageSize),
      lastDoc != null ? startAfter(lastDoc) : startAfter(0)
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    console.log("Current Page Changed:", currentPage);
  }, [currentPage]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      {value.docs.map((doc) => (
        <div key={doc.id}>{doc.id}</div>
      ))}
      <div className="gap-10">
        <button
          onClick={() => {
            setCurrentPage(currentPage - 1);
            setLastDoc(value.docs[value.docs.length]);
          }}
          disabled={currentPage === 1} // İlk sayfadaysa önceki sayfa butonunu devre dışı bırak
        >
          Önceki Sayfa
        </button>
        <button
          onClick={() => {
            setCurrentPage(currentPage + 1);
            console.log(
              `GELEN LENGTH DEGERI = ${value.docs[value.docs.length]}`
            );
            setLastDoc(value.docs[value.docs.length - 1]);
          }}
          disabled={value.docs.length < pageSize} // Sayfa dolu değilse sonraki sayfa butonunu devre dışı bırak
        >
          Sonraki Sayfa
        </button>
      </div>
    </div>
  );
}

export default TestPage;




*/

/*
 
 
        <div>
            <h1 className='text-2xl mt-5'>Prototip Hizmeti</h1>
            <div className="relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
 
                <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">İsim</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Yeni mesaj</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Firma</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Hizmet</th>
 
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 border-t border-gray-100">
 
 
 
                        <tr className="hover:bg-gray-50">
                            <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                <div className="relative h-10 w-10">
                                    <FaUser className='h-full w-full'></FaUser>
                                    <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                </div>
                                <div className="text-sm">
                                    <div className="font-medium text-gray-700">Steven Jobs</div>
                                    <div className="text-gray-400">jobs@sailboatui.com</div>
                                </div>
                            </th>
                            <td className="px-6 py-4">
                                <span
                                    className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                >
                                    <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                    Yeni Mesaj
                                </span>
                            </td>
                            <td className="px-6 py-4">Aselsan</td>
                            <td className="px-6 py-4">
                                <div className="flex gap-2">
                                    <span
                                        className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                                    >
                                        Prototip Hizmeti
                                    </span>
 
 
                                </div>
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex justify-end gap-4">
 
                                    <button>
                                        <AiOutlineArrowRight size={25}></AiOutlineArrowRight>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
 
*/

/*
 
 
import React from 'react'
import { FaUser } from "react-icons/fa"
import { AiOutlineArrowRight } from "react-icons/ai"
import { FirebaseApp } from "../../../FirebaseConfig"
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, getFirestore } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
const auth = getAuth(FirebaseApp);
function Prototipleme() {
    const [value, loading, error] = useCollection(
        collection(getFirestore(FirebaseApp), 'USERS'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const login = async () => {
 
        console.log("girdi")
 
        let authDurum = await signInWithEmailAndPassword(auth, "admin@gmail.com", "orsa123");
        console.log(authDurum)
    }
    return (
        <div>
            <p>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <span>Collection: Loading...</span>}
                {value && (
                    <div>
                        <h1 className='text-2xl mt-5'>Prototip Hizmeti</h1>
                        <div className="relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
                            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">İsim</th>
                                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Yeni mesaj</th>
                                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Firma</th>
                                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Hizmet</th>
 
                                    </tr>
                                </thead>
                                {value.docs.map((doc, index) => (
                                    <div key={index}>
                                
                                        {doc.data()["prototipHizmeti"].map((item, itemIndex) => (
                                            <div key={itemIndex}>
                                                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
 
 
 
                                                    <tr className="hover:bg-gray-50">
                                                        <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                                            <div className="relative h-10 w-10">
                                                                <FaUser className='h-full w-full'></FaUser>
                                                                <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                                            </div>
                                                            <div className="text-sm">
                                                                <div className="font-medium text-gray-700">Steven Jobs</div>
                                                                <div className="text-gray-400">jobs@sailboatui.com</div>
                                                            </div>
                                                        </th>
                                                        <td className="px-6 py-4">
                                                            <span
                                                                className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                                            >
                                                                <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                                                Yeni Mesaj
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4">Aselsan</td>
                                                        <td className="px-6 py-4">
                                                            <div className="flex gap-2">
                                                                <span
                                                                    className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                                                                >
                                                                    Prototip Hizmeti
                                                                </span>
 
 
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            <div className="flex justify-end gap-4">
 
                                                                <button>
                                                                    <AiOutlineArrowRight size={25}></AiOutlineArrowRight>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </table>
                        </div>
                    </div>
                )}
            </p>
            <button onClick={() => login()}>Login</button>
        </div>
 
    )
}
 
export default Prototipleme
 
 
/*
 
 
        <div>
            <h1 className='text-2xl mt-5'>Prototip Hizmeti</h1>
            <div className="relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
 
                <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">İsim</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Yeni mesaj</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Firma</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Hizmet</th>
 
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 border-t border-gray-100">
 
 
 
                        <tr className="hover:bg-gray-50">
                            <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                <div className="relative h-10 w-10">
                                    <FaUser className='h-full w-full'></FaUser>
                                    <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                </div>
                                <div className="text-sm">
                                    <div className="font-medium text-gray-700">Steven Jobs</div>
                                    <div className="text-gray-400">jobs@sailboatui.com</div>
                                </div>
                            </th>
                            <td className="px-6 py-4">
                                <span
                                    className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                >
                                    <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                    Yeni Mesaj
                                </span>
                            </td>
                            <td className="px-6 py-4">Aselsan</td>
                            <td className="px-6 py-4">
                                <div className="flex gap-2">
                                    <span
                                        className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                                    >
                                        Prototip Hizmeti
                                    </span>
 
 
                                </div>
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex justify-end gap-4">
 
                                    <button>
                                        <AiOutlineArrowRight size={25}></AiOutlineArrowRight>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
 
*/

import React from "react";
import Sidebar from "./component/Sidebar";

import { useRecoilState } from "recoil";
import { countState } from "./state/SideBarState";
import GelenKutusu from "./component/Body/GelenKutusu/GelenKutusu";

function HomePage() {
  const [count, setCount] = useRecoilState(countState);

  return (
    <main className="h-full">
      <section>
        <Sidebar></Sidebar>
      </section>
      <section>
        <GelenKutusu></GelenKutusu>
      </section>
    </main>
  );
}

export default HomePage;

import React from 'react'

function LoadingScreen() {
    return (
        <div className='flex w-full h-[100vh] justify-center items-center'>
            <div className="block mt-10 items-center justify-center ">

                <div
                    className=" top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
                    style={{
                        borderTop: '2px solid transparent',
                        borderBottom: '2px solid transparent',
                    }}
                ></div>
            </div>
        </div>
    )
}

export default LoadingScreen
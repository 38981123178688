import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowDown } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { getAuth } from "firebase/auth";
import { FirebaseApp } from "../../../FirebaseConfig";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { collection, doc, getFirestore } from "firebase/firestore";
import { FiSearch } from "react-icons/fi";
import moment from "moment";
import "moment/locale/tr";

import { Menu, Transition } from "@headlessui/react";
import { AiOutlineWhatsApp } from "react-icons/ai";

import Modal from "./PrototipModal";
import { useRecoilState } from "recoil";
import {
  PrototipModalData,
  PrototipModalState,
  TaramaModalData,
  TaramaModalState,
  counterExample,
} from "../../../state/SideBarState";
import axios from "axios";
import TaramaModal from "./TaramaModal";

const auth = getAuth(FirebaseApp);

function Detail() {
  const [prototipModalOpen, setprototipModalOpen] =
    useRecoilState(PrototipModalState);

  const [prototipModalVeri, prototipModalSetVeri] =
    useRecoilState(PrototipModalData);

  const [taramaModalOpen, settaramaModalOpen] =
    useRecoilState(TaramaModalState);

  const [taramaModalVeri, taramaModalSetVeri] = useRecoilState(TaramaModalData);
  const [counterVal, setCounterVal] = useRecoilState(counterExample);
  useEffect(() => {
    moment.locale("tr");
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  let [epostaKonu, setEpostaKonu] = useState("");
  let [epostaGonderLoading, setEpostaGonderLoading] = useState(false);
  let [epostaMesaj, setEpostaMesaj] = useState("");

  const userId = location.state ? location.state.userId : null;
  let [isOpen, setIsOpen] = useState(true);
  const isim = location.state ? location.state.isim : null;

  const eposta = location.state ? location.state.eposta : null;

  const telefonNumarası = location.state ? location.state.telefon : null;
  const firmaIsmi = location.state ? location.state.firma : null;
  const [value, loading, error] = useDocument(
    doc(getFirestore(FirebaseApp), "USERS", userId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const prototipModalTog = () => {
    setprototipModalOpen(!prototipModalOpen);
  };

  const prototipModalDataFunc = (data) => {
    prototipModalSetVeri(data);
    prototipModalTog();
  };

  const taramaModalTog = () => {
    settaramaModalOpen(!taramaModalOpen);
  };

  const taramaModalDataFunc = (data) => {
    taramaModalSetVeri(data);
    taramaModalTog();
  };

  const mailGonder = async () => {
    setEpostaGonderLoading(true);
    const data = {
      toMail: eposta,
      headerData: epostaKonu,
      bodyData: `	<!DOCTYPE html>
            <html lang="tr">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>E-posta Tasarımı</title>
            </head>
            
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4;">
                <div style="text-align: center; padding: 20px;">
                <img src="https://firebasestorage.googleapis.com/v0/b/robohive-9c678.appspot.com/o/weblogo.jpg?alt=media&token=5d7bd842-cfad-4dbb-a8d4-ca73b37e35cc"
                height="80px" />
                    <p style="font-size: 18px;">Merhaba,<br>${epostaMesaj}</p>
                </div>
            </body>
            
            </html>
            `,
    };

    try {
      const response = await axios.post(
        "https://nodemail.calybre3d.com/mail/teklif",
        data
      );
      console.log("Response:", response.data);
      setEpostaKonu("");
      setEpostaMesaj("");
      setEpostaGonderLoading(false);
    } catch (error) {
      setEpostaGonderLoading(false);
      console.error("Error:", error);
    }
  };

  let navi = useNavigate();

  useEffect(() => {
    if (
      Detail == null ||
      isim == null ||
      eposta == null ||
      telefonNumarası == null ||
      firmaIsmi == null
    ) {
      navigate(-1);
    }
  });
  return (
    <div>
      <p>
        {error && <strong>Hata: {JSON.stringify(error)}</strong>}
        {loading && <span>Yükleniyor...</span>}
        {value && (
          <div className="h-screen ">
            <nav className="bg-gray-900 p-4 flex justify-between items-center">
              <button
                onClick={() => navigate(-1)}
                className="text-white font-bold text-lg"
              >
                <AiOutlineArrowLeft></AiOutlineArrowLeft>
              </button>
              <h1 className="text-white text-md font-semibold">{counterVal}</h1>
              <div></div>
            </nav>

            <div className="px-2 sm:px-2 md:px-5 lg:px-5">
              <div className="block rounded-md mt-5  ">
                <div className="block sm:block md:flex lg:flex w-full justify-between">
                  <div className="w-1/4">
                    <div className="block bg-gray-100 h-96 mt-10 space-y-20 mx-2">
                      <div>
                        <h1 className="text-xl sm:text-xl lg:text-sm w-52 font-light">
                          İsim:{" "}
                          <span className="text-xl sm:text-xl lg:text-sm font-thin">
                            {isim}
                          </span>
                        </h1>
                      </div>

                      <div>
                        <h1 className="text-xl eleva sm:text-xl lg:text-sm w-52 font-light">
                          Eposta:{" "}
                          <span className="text-xl sm:text-xl lg:text-sm font-thin">
                            {eposta}
                          </span>
                        </h1>
                      </div>

                      <div>
                        {" "}
                        <h1 className="text-xl sm:text-xl lg:text-sm w-52 font-light">
                          Telefon Numarası:{" "}
                          <span className="text-xl sm:text-xl lg:text-sm font-thin">
                            {telefonNumarası}
                          </span>
                        </h1>
                      </div>

                      <div>
                        {" "}
                        <h1 className="text-xl  sm:text-xl lg:text-sm w-52 font-light">
                          Firma İsmi:{" "}
                          <span className="text-xl sm:text-xl lg:text-sm font-thin">
                            {firmaIsmi}
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/4">
                    <div className="bg-gray-100 flex items-center justify-center ">
                      <div className=" p-8 rounded shadow-md w-full">
                        <h2 className="text-2xl font-semibold mb-4">
                          E-posta Gönder
                        </h2>
                        <div className="mb-4 ">
                          <label className="block mb-1 font-semibold">
                            Alıcı E-posta
                          </label>
                          <input
                            type="email"
                            value={eposta}
                            disabled
                            className="w-full p-2 border rounded text-gray-400"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block mb-1 font-semibold">
                            Konu
                          </label>
                          <input
                            type="text"
                            value={epostaKonu}
                            onChange={(e) => {
                              setEpostaKonu(e.target.value);
                            }}
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block mb-1 font-semibold">
                            Mesaj
                          </label>
                          <textarea
                            onChange={(e) => {
                              setEpostaMesaj(e.target.value);
                            }}
                            className="w-full p-2 border rounded"
                            rows="4"
                            required
                            value={epostaMesaj}
                          />
                        </div>
                        <div className="w-full text-end">
                          {epostaGonderLoading === false ? (
                            <>
                              <button
                                onClick={mailGonder}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                              >
                                Gönder
                              </button>
                            </>
                          ) : (
                            <h1>Gönderiliyor...</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/4">
                    <div className="bg-gray-100 flex items-center justify-center ">
                      <form className=" p-8 rounded shadow-md w-full">
                        <h2 className="text-2xl font-semibold mb-4">
                          WhatsApp Mesaj
                        </h2>
                        <div className="mb-4 ">
                          <label className="block mb-1 font-semibold">
                            Alıcı Telefon
                          </label>
                          <input
                            type="text"
                            disabled
                            value={telefonNumarası}
                            className="w-full p-2 border rounded text-gray-400"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block mb-1 font-semibold">
                            Konu
                          </label>
                          <input
                            type="text"
                            className="w-full p-2 border rounded"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block mb-1 font-semibold">
                            Mesaj
                          </label>
                          <textarea
                            className="w-full p-2 border rounded"
                            rows="4"
                            required
                          />
                        </div>
                        <div className="w-full text-end">
                          <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                          >
                            Gönder
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="block mx-10 ">
                    <div className="bg-blue-500 mb-6 p-5 mt-12 rounded-2xl">
                      <h1 className="text-sm sm:text-sm lg:text-lg ">
                        Prototip Hizmeti:{" "}
                        <span className="text-lg font-bold">
                          {value.data().prototipHizmeti.length}
                        </span>
                      </h1>
                    </div>

                    <div className="bg-green-500 mb-6 p-5 rounded-2xl">
                      <h1 className="text-sm sm:text-sm lg:text-lg ">
                        Kalite Kontrol Hizmeti:{" "}
                        <span className="text-lg font-bold">
                          {value.data().taramaHizmeti.length}
                        </span>
                      </h1>
                    </div>

                    <div className="bg-yellow-500 mb-6 p-5 rounded-2xl">
                      <h1 className="text-sm sm:text-sm lg:text-lg ">
                        OSR Hizmeti:{" "}
                        <span className="text-lg font-bold">0</span>
                      </h1>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>

              <div className="block w-full sm:w-full md:w-auto lg:w-auto sm:block md:flex lg:flex justify-between  ">
                <div className="w-full sm:w-full md:w-1/2 lg:w-1/2">
                  <h1 className="mt-3 text-2xl font-extralight">
                    Tarama Hizmeti
                  </h1>
                  <div className="h-96 rounded-md mt-2 overflow-auto">
                    <div className="min-h-screen relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
                      <table className=" w-full border-collapse bg-white text-center text-sm text-gray-500">
                        <thead className=" w-full bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-4 font-medium text-gray-900"
                            >
                              Malzeme Çeşidi
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-4 font-medium text-gray-900"
                            >
                              İncelenme Durumu
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-4 font-medium text-gray-900"
                            >
                              Boyut Bilgisi
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-4 font-medium text-gray-900"
                            >
                              Tarih
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-4 font-medium text-gray-900"
                            >
                              İndir
                            </th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                          {value &&
                            value.data().taramaHizmeti &&
                            value
                              .data()
                              .taramaHizmeti.map((item, itemIndex) => ({
                                id: itemIndex,
                                ...item,
                              }))
                              .sort((a, b) => {
                                // İncelenme durumuna göre sıralama yap
                                if (
                                  a.incelenmeDurumu === false &&
                                  b.incelenmeDurumu !== false
                                ) {
                                  return -1; // a önce gelsin
                                }
                                if (
                                  a.incelenmeDurumu !== false &&
                                  b.incelenmeDurumu === false
                                ) {
                                  return 1; // b önce gelsin
                                }
                                // İncelenme durumu aynı ise tarihe göre sırala
                                return b.zaman - a.zaman;
                              })
                              .map((item) => (
                                <tr key={item.id} className="hover:bg-gray-50">
                                  <th className="flex gap-3 px-12 py-4 font-normal text-gray-900">
                                    <div className="py-4 text-sm">
                                      <div className="font-sm text-gray-700">
                                        {item.malzemeCesidi}
                                      </div>
                                    </div>
                                  </th>
                                  <td className="px-6 py-4">
                                    <span
                                      className={`inline-flex items-center gap-1 rounded-full ${
                                        item.incelenmeDurumu
                                          ? "bg-gray-50"
                                          : "bg-green-50"
                                      } px-2 py-1 text-xs font-semibold  ${
                                        item.incelenmeDurumu
                                          ? "text-gray-600"
                                          : "text-green-600"
                                      }`}
                                    >
                                      <span
                                        className={`h-1.5 w-1.5 rounded-full ${
                                          item.incelenmeDurumu
                                            ? "bg-gray-600"
                                            : "bg-green-600"
                                        } `}
                                      ></span>
                                      {item.incelenmeDurumu
                                        ? "İncelendi"
                                        : "İncelenmedi"}
                                    </span>
                                  </td>
                                  <td className="px-4 py-4">
                                    {item.boyutBilgisi}
                                  </td>
                                  <td className="px-6 py-4">
                                    <div className="flex gap-2">
                                      <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                                        {moment(item.zaman).calendar()}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    <div className="flex justify-end gap-4">
                                      <Menu
                                        as="div"
                                        className="block text-left"
                                      >
                                        <div>
                                          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-500  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-60 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            İncele
                                            <FiSearch
                                              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                              aria-hidden="true"
                                            />
                                          </Menu.Button>
                                        </div>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="px-1 py-1">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    onClick={() => {
                                                      taramaModalDataFunc({
                                                        id: 0,
                                                        product: item,
                                                        isim: isim,
                                                        telefonNumrasi:
                                                          telefonNumarası,
                                                        firmaIsmi: firmaIsmi,
                                                      });
                                                    }}
                                                    className={`${
                                                      active
                                                        ? "bg-blue-500 text-white"
                                                        : "text-gray-900"
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >
                                                    {active ? (
                                                      <BiMailSend
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    ) : (
                                                      <BiMailSend
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    )}
                                                    Teklif Gönder
                                                  </button>
                                                )}
                                              </Menu.Item>

                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    onClick={() => {
                                                      taramaModalDataFunc({
                                                        id: 1,
                                                        product: item,
                                                        isim: isim,
                                                        telefonNumrasi:
                                                          telefonNumarası,
                                                        eposta: eposta,
                                                        firmaIsmi: firmaIsmi,
                                                      });
                                                    }}
                                                    className={`${
                                                      active
                                                        ? "bg-blue-500 text-white"
                                                        : "text-gray-900"
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >
                                                    {active ? (
                                                      <AiOutlineWhatsApp
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    ) : (
                                                      <AiOutlineWhatsApp
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    )}
                                                    Mesaj Gönder
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                            <div className="px-1 py-1">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    onClick={() => {
                                                      taramaModalDataFunc({
                                                        id: 2,
                                                        product: item,
                                                        isim: isim,
                                                        telefonNumarasi:
                                                          telefonNumarası,
                                                        firmaIsmi: firmaIsmi,
                                                        eposta: eposta,
                                                      });
                                                    }}
                                                    className={`${
                                                      active
                                                        ? "bg-blue-500 text-white"
                                                        : "text-gray-900"
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >
                                                    {active ? (
                                                      <AiOutlineArrowDown
                                                        className="mr-2 h-5 w-5 "
                                                        aria-hidden="true"
                                                      />
                                                    ) : (
                                                      <AiOutlineArrowDown
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    )}
                                                    Dosyaları İndir
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className=" w-full sm:w-full md:w-1/2 lg:w-1/2">
                  <h1 className="mt-3 text-2xl font-extralight">
                    Prototipleme
                  </h1>
                  <div className="h-96 rounded-md mt-2 px-5 overflow-auto">
                    <div className="min-h-screen relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
                      <table className=" w-full border-collapse bg-white text-center text-sm text-gray-500">
                        <thead className=" w-full bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              Malzeme Çeşidi
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              İncelenme Durumu
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              Boyut Bilgisi
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              Baski Teknolojisi
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              Malzeme Adet
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              Tarih
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-4 font-medium text-gray-900"
                            >
                              İndir
                            </th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                          {value &&
                            value.data().prototipHizmeti &&
                            value
                              .data()
                              .prototipHizmeti.map((item, itemIndex) => ({
                                id: itemIndex,
                                ...item,
                              }))
                              .sort((a, b) => {
                                // İncelenme durumuna göre sıralama yap
                                if (
                                  a.incelenmeDurumu === false &&
                                  b.incelenmeDurumu !== false
                                ) {
                                  return -1; // a önce gelsin
                                }
                                if (
                                  a.incelenmeDurumu !== false &&
                                  b.incelenmeDurumu === false
                                ) {
                                  return 1; // b önce gelsin
                                }
                                // İncelenme durumu aynı ise tarihe göre sırala
                                return b.zaman - a.zaman;
                              })
                              .map((prototip) => (
                                <tr
                                  key={prototip.id}
                                  className="hover:bg-gray-50"
                                >
                                  <th className="flex gap-3 px-2 py-4 font-normal text-gray-900">
                                    <div className="text-sm">
                                      <div className="font-sm text-gray-700">
                                        {prototip.malzemeCesidi}
                                      </div>
                                    </div>
                                  </th>
                                  <td className="px-2 py-4">
                                    <span
                                      className={`inline-flex items-center gap-1 rounded-full ${
                                        prototip.incelenmeDurumu
                                          ? "bg-gray-50"
                                          : "bg-green-50"
                                      } px-2 py-1 text-xs font-semibold  ${
                                        prototip.incelenmeDurumu
                                          ? "text-gray-600"
                                          : "text-green-600"
                                      }`}
                                    >
                                      <span
                                        className={`h-1.5 w-1.5 rounded-full ${
                                          prototip.incelenmeDurumu
                                            ? "bg-gray-600"
                                            : "bg-green-600"
                                        } `}
                                      ></span>
                                      {prototip.incelenmeDurumu
                                        ? "İncelendi"
                                        : "İncelenmedi"}
                                    </span>
                                  </td>
                                  <td className="px-2 py-4">
                                    {prototip.boyutBilgisi}
                                  </td>
                                  <td className="px-2 py-4">
                                    <div className="text-sm">
                                      <div className="font-sm text-gray-700">
                                        {prototip.baskiTeknolojisi}
                                      </div>
                                    </div>
                                  </td>

                                  <td className="px-2 py-4">
                                    <div className="text-sm">
                                      <div className="font-sm text-gray-700">
                                        {prototip.malzemeAdet}
                                      </div>
                                    </div>
                                  </td>

                                  <td className="px-2 py-4">
                                    <div className="flex gap-2">
                                      <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                                        {moment(prototip.zaman).calendar()}
                                      </span>
                                    </div>
                                  </td>

                                  <td className="px-4 pr-2 py-4">
                                    <div className="flex justify-end gap-4">
                                      <div className="flex top-16  text-right">
                                        <Menu
                                          as="div"
                                          className="block text-left"
                                        >
                                          <div>
                                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-500  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-60 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                              İncele
                                              <FiSearch
                                                className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                                aria-hidden="true"
                                              />
                                            </Menu.Button>
                                          </div>
                                          <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="px-1 py-1">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => {
                                                        console.log(prototip);
                                                        prototipModalDataFunc({
                                                          id: 0,
                                                          product: prototip,
                                                          isim: isim,
                                                          telefonNumrasi:
                                                            telefonNumarası,
                                                          firmaIsmi: firmaIsmi,
                                                          uuid: userId,
                                                        });
                                                      }}
                                                      className={`${
                                                        active
                                                          ? "bg-blue-500 text-white"
                                                          : "text-gray-900"
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      {active ? (
                                                        <BiMailSend
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      ) : (
                                                        <BiMailSend
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      )}
                                                      Teklif Gönder
                                                    </button>
                                                  )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => {
                                                        prototipModalDataFunc({
                                                          id: 1,
                                                          product: prototip,
                                                          isim: isim,
                                                          telefonNumrasi:
                                                            telefonNumarası,
                                                          eposta: eposta,
                                                          firmaIsmi: firmaIsmi,
                                                        });
                                                      }}
                                                      className={`${
                                                        active
                                                          ? "bg-blue-500 text-white"
                                                          : "text-gray-900"
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      {active ? (
                                                        <AiOutlineWhatsApp
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      ) : (
                                                        <AiOutlineWhatsApp
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      )}
                                                      Mesaj Gönder
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                              <div className="px-1 py-1">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => {
                                                        prototipModalDataFunc({
                                                          id: 2,
                                                          product: prototip,
                                                          isim: isim,
                                                          telefonNumarasi:
                                                            telefonNumarası,
                                                          firmaIsmi: firmaIsmi,
                                                          eposta: eposta,
                                                        });
                                                      }}
                                                      className={`${
                                                        active
                                                          ? "bg-blue-500 text-white"
                                                          : "text-gray-900"
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      {active ? (
                                                        <AiOutlineArrowDown
                                                          className="mr-2 h-5 w-5 "
                                                          aria-hidden="true"
                                                        />
                                                      ) : (
                                                        <AiOutlineArrowDown
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      )}
                                                      Dosyaları İndir
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                            </Menu.Items>
                                          </Transition>
                                        </Menu>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </p>

      {prototipModalOpen && <Modal></Modal>}
      {taramaModalOpen && <TaramaModal></TaramaModal>}
    </div>
  );
}

export default Detail;

/*
       <div className="border-l-2 border-gray-500 h-80 my-auto"></div>

 <button onClick={async () => {
                                                                    //await prototipHizmetiIndir(prototip.dosyaLink, prototip.dosyaIsmi, prototip.boyutBilgisi, prototip.malzemeAdet, prototip.malzemeCesidi, prototip.baskiTeknolojisi)
                                                                }} >
                                                                    <AiOutlineArrowDown size={25}></AiOutlineArrowDown>
                                                                </button>



                                                                */

/*
TARAMA HIZMETI

                                <div className='h-80 rounded-md mt-2 px-5 overflow-auto'>

                                    <div className="relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
                                        <table className=" w-full border-collapse bg-white text-center text-sm text-gray-500">
                                            <thead className=" w-full bg-gray-50">
                                                <tr >
                                                    <th scope="col" className="px-12 py-4 font-medium text-gray-900">Malzeme Çeşidi</th>
                                                    <th scope="col" className="px-12 py-4 font-medium text-gray-900">İncelenme Durumu</th>
                                                    <th scope="col" className="px-12 py-4 font-medium text-gray-900">Boyut Bilgisi</th>
                                                    <th scope="col" className="px-12 py-4 font-medium text-gray-900">Tarih</th>
                                                    <th scope="col" className="px-12 py-4 font-medium text-gray-900">İndir</th>

                                                </tr>
                                            </thead>

                                            <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                                                {value && value.data().taramaHizmeti.map((item, itemIndex) => (
                                                    <tr key={itemIndex} className="hover:bg-gray-50">
                                                        <th className="flex gap-3 px-12 py-4 font-normal text-gray-900">
                                                            <div className="py-4 text-sm">
                                                                <div className="font-sm text-gray-700">{item.malzemeCesidi}</div>
                                                            </div>
                                                        </th>
                                                        <td className="px-12 py-4">
                                                            <span className={`inline-flex items-center gap-1 rounded-full ${item.incelenmeDurumu ? "bg-gray-50" : "bg-green-50"} px-2 py-1 text-xs font-semibold  ${item.incelenmeDurumu ? "text-gray-600" : "text-green-600"}`}>
                                                                <span className={`h-1.5 w-1.5 rounded-full ${item.incelenmeDurumu ? "bg-gray-600" : "bg-green-600"} `}></span>
                                                                {item.incelenmeDurumu ? "İncelendi" : "İncelenmedi"}
                                                            </span>
                                                        </td>
                                                        <td className="px-4 py-4">{item.boyutBilgisi}</td>
                                                        <td className="px-12 py-4">
                                                            <div className="flex gap-2">
                                                                <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                                                                    {moment(item.zaman).calendar()}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="px-12 py-4">
                                                            <div className="flex justify-end gap-4">
                                                                <button onClick={async () => {
                                                                    await taramaHizmetiIndir(item.dosyaLink[0], item.dosyaLink[1], item.dosyaLink[2], item.dosyaLink[3], firmaIsmi, item.malzemeCesidi, item.boyutBilgisi);

                                                                }}>
                                                                    <AiOutlineArrowDown size={25}></AiOutlineArrowDown>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>



                                        </table>
                                    </div>

                                </div>



*/

/*
PROTOTİPLEME HİZMETİ

     <div className='h-80 rounded-md mt-2 px-5 overflow-auto'>

                                    <div className="relative overflow-hidden mx-auto rounded-lg border border-gray-200 shadow-md m-5">
                                        <table className="w-full border-collapse bg-white text-center text-sm text-gray-500">
                                            <thead className=" w-full bg-gray-50">
                                                <tr >
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">Malzeme Çeşidi</th>
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">İncelenme Durumu</th>
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">Boyut Bilgisi</th>
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">Baski Teknolojisi</th>
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">Malzeme Adet</th>
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">Tarih</th>
                                                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">İndir</th>


                                                </tr>
                                            </thead>


                                            <tbody className="divide-y divide-gray-100 border-t border-gray-100">

                                                {value && value.data().prototipHizmeti.map((prototip, itemIndex) => (

                                                    <tr className="hover:bg-gray-50">
                                                        <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                                            <div className="text-sm">
                                                                <div className="font-sm text-gray-700">{prototip.malzemeCesidi}</div>

                                                            </div>

                                                        </th>
                                                        <td className="px-6 py-4">
                                                            <span className={`inline-flex items-center gap-1 rounded-full ${prototip.incelenmeDurumu ? "bg-gray-50" : "bg-green-50"} px-2 py-1 text-xs font-semibold  ${prototip.incelenmeDurumu ? "text-gray-600" : "text-green-600"}`}>
                                                                <span className={`h-1.5 w-1.5 rounded-full ${prototip.incelenmeDurumu ? "bg-gray-600" : "bg-green-600"} `}></span>
                                                                {prototip.incelenmeDurumu ? "İncelendi" : "İncelenmedi"}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4">{prototip.boyutBilgisi}</td>
                                                        <td className="px-6 py-4">
                                                            <div className="text-sm">
                                                                <div className="font-sm text-gray-700">{prototip.baskiTeknolojisi}</div>

                                                            </div>
                                                        </td>

                                                        <td className="px-6 py-4">

                                                            <div className="text-sm">
                                                                <div className="font-sm text-gray-700">{prototip.malzemeAdet}</div>

                                                            </div>

                                                        </td>

                                                        <td className="px-6 py-4">



                                                            <div className="flex gap-2">
                                                                <span
                                                                    className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                                                                >
                                                                    {moment(prototip.zaman).calendar()}
                                                                </span>


                                                            </div>

                                                        </td>

                                                        <td className="px-6 py-4">
                                                            <div className="flex justify-end gap-4">

                                                                <button onClick={async () => {
                                                                    await prototipHizmetiIndir(prototip.dosyaLink, prototip.dosyaIsmi, prototip.boyutBilgisi, prototip.malzemeAdet, prototip.malzemeCesidi, prototip.baskiTeknolojisi)
                                                                }} >
                                                                    <AiOutlineArrowDown size={25}></AiOutlineArrowDown>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}




                                            </tbody>



                                        </table>
                                    </div>
                                </div>


*/

/*

           {value && value.data().taramaHizmeti.map((item, itemIndex) => (
                                                    <tr key={itemIndex} className="hover:bg-gray-50">
                                                        <th className="flex gap-3 px-12 py-4 font-normal text-gray-900">
                                                            <div className="py-4 text-sm">
                                                                <div className="font-sm text-gray-700">{item.malzemeCesidi}</div>
                                                            </div>
                                                        </th>
                                                        <td className="px-6 py-4">
                                                            <span className={`inline-flex items-center gap-1 rounded-full ${item.incelenmeDurumu ? "bg-gray-50" : "bg-green-50"} px-2 py-1 text-xs font-semibold  ${item.incelenmeDurumu ? "text-gray-600" : "text-green-600"}`}>
                                                                <span className={`h-1.5 w-1.5 rounded-full ${item.incelenmeDurumu ? "bg-gray-600" : "bg-green-600"} `}></span>
                                                                {item.incelenmeDurumu ? "İncelendi" : "İncelenmedi"}
                                                            </span>
                                                        </td>
                                                        <td className="px-4 py-4">{item.boyutBilgisi}</td>
                                                        <td className="px-6 py-4">
                                                            <div className="flex gap-2">
                                                                <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                                                                    {moment(item.zaman).calendar()}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            <div className="flex justify-end gap-4">
                                                                <Menu as="div" className="block text-left">
                                                                    <div>
                                                                        <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-500  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-60 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                                            İncele
                                                                            <FiSearch
                                                                                className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                                            <div className="px-1 py-1">
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                taramaModalDataFunc({ "id": 0, "product": item, "isim": isim, "telefonNumrasi": telefonNumarası, "firmaIsmi": firmaIsmi })
                                                                                            }}
                                                                                            className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            {active ? (
                                                                                                <BiMailSend
                                                                                                    className="mr-2 h-5 w-5"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            ) : (
                                                                                                <BiMailSend
                                                                                                    className="mr-2 h-5 w-5"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            )}
                                                                                            Teklif Gönder
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>

                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                taramaModalDataFunc({ "id": 1, "product": item, "isim": isim, "telefonNumrasi": telefonNumarası, "eposta": eposta, "firmaIsmi": firmaIsmi })
                                                                                            }}
                                                                                            className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            {active ? (
                                                                                                <AiOutlineWhatsApp
                                                                                                    className="mr-2 h-5 w-5"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            ) : (
                                                                                                <AiOutlineWhatsApp
                                                                                                    className="mr-2 h-5 w-5"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            )}
                                                                                            Mesaj Gönder
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>

                                                                            </div>
                                                                            <div className="px-1 py-1">
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                taramaModalDataFunc({ "id": 2, "product": item, "isim": isim, "telefonNumarasi": telefonNumarası, "firmaIsmi": firmaIsmi, "eposta": eposta })
                                                                                            }}
                                                                                            className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            {active ? (
                                                                                                <AiOutlineArrowDown
                                                                                                    className="mr-2 h-5 w-5 "
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            ) : (
                                                                                                <AiOutlineArrowDown
                                                                                                    className="mr-2 h-5 w-5"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            )}
                                                                                            Dosyaları İndir
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}


*/

/*




                                                    <tr className="hover:bg-gray-50">
                                                        <th className="flex gap-3 px-2 py-4 font-normal text-gray-900">
                                                            <div className="text-sm">
                                                                <div className="font-sm text-gray-700">{prototip.malzemeCesidi}</div>

                                                            </div>

                                                        </th>
                                                        <td className="px-2 py-4">
                                                            <span className={`inline-flex items-center gap-1 rounded-full ${prototip.incelenmeDurumu ? "bg-gray-50" : "bg-green-50"} px-2 py-1 text-xs font-semibold  ${prototip.incelenmeDurumu ? "text-gray-600" : "text-green-600"}`}>
                                                                <span className={`h-1.5 w-1.5 rounded-full ${prototip.incelenmeDurumu ? "bg-gray-600" : "bg-green-600"} `}></span>
                                                                {prototip.incelenmeDurumu ? "İncelendi" : "İncelenmedi"}
                                                            </span>
                                                        </td>
                                                        <td className="px-2 py-4">{prototip.boyutBilgisi}</td>
                                                        <td className="px-2 py-4">
                                                            <div className="text-sm">
                                                                <div className="font-sm text-gray-700">{prototip.baskiTeknolojisi}</div>

                                                            </div>
                                                        </td>

                                                        <td className="px-2 py-4">

                                                            <div className="text-sm">
                                                                <div className="font-sm text-gray-700">{prototip.malzemeAdet}</div>

                                                            </div>

                                                        </td>

                                                        <td className="px-2 py-4">



                                                            <div className="flex gap-2">
                                                                <span
                                                                    className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                                                                >
                                                                    {moment(prototip.zaman).calendar()}
                                                                </span>


                                                            </div>

                                                        </td>

                                                        <td className="px-4 pr-2 py-4">
                                                            <div className="flex justify-end gap-4">


                                                                <div className="flex top-16  text-right">
                                                                    <Menu as="div" className="block text-left">
                                                                        <div>
                                                                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-500  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-60 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                                                İncele
                                                                                <FiSearch
                                                                                    className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                                                <div className="px-1 py-1">
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    prototipModalDataFunc({ "id": 0, "product": prototip, "isim": isim, "telefonNumrasi": telefonNumarası, "firmaIsmi": firmaIsmi })
                                                                                                }}
                                                                                                className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                            >
                                                                                                {active ? (
                                                                                                    <BiMailSend
                                                                                                        className="mr-2 h-5 w-5"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <BiMailSend
                                                                                                        className="mr-2 h-5 w-5"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                )}
                                                                                                Teklif Gönder
                                                                                            </button>
                                                                                        )}
                                                                                    </Menu.Item>

                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    prototipModalDataFunc({ "id": 1, "product": prototip, "isim": isim, "telefonNumrasi": telefonNumarası, "eposta": eposta, "firmaIsmi": firmaIsmi })
                                                                                                }}
                                                                                                className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                            >
                                                                                                {active ? (
                                                                                                    <AiOutlineWhatsApp
                                                                                                        className="mr-2 h-5 w-5"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <AiOutlineWhatsApp
                                                                                                        className="mr-2 h-5 w-5"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                )}
                                                                                                Mesaj Gönder
                                                                                            </button>
                                                                                        )}
                                                                                    </Menu.Item>

                                                                                </div>
                                                                                <div className="px-1 py-1">
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    prototipModalDataFunc({ "id": 2, "product": prototip, "isim": isim, "telefonNumarasi": telefonNumarası, "firmaIsmi": firmaIsmi, "eposta": eposta })
                                                                                                }}
                                                                                                className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                            >
                                                                                                {active ? (
                                                                                                    <AiOutlineArrowDown
                                                                                                        className="mr-2 h-5 w-5 "
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <AiOutlineArrowDown
                                                                                                        className="mr-2 h-5 w-5"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                )}
                                                                                                Dosyaları İndir
                                                                                            </button>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>




                                                    */

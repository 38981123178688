// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBUrVic3OQ5rXux6JWYo5OKXxbLyeqdTc",
  authDomain: "robohive-9c678.firebaseapp.com",
  projectId: "robohive-9c678",
  storageBucket: "robohive-9c678.appspot.com",
  messagingSenderId: "568195290486",
  appId: "1:568195290486:web:98c96092ce59bac7299657",
  measurementId: "G-Q6HEQ7FXZ7",
};
// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(FirebaseApp);

/*

const firebaseConfig = {
    apiKey: "AIzaSyDBUrVic3OQ5rXux6JWYo5OKXxbLyeqdTc",
    authDomain: "robohive-9c678.firebaseapp.com",
    projectId: "robohive-9c678",
    storageBucket: "robohive-9c678.appspot.com",
    messagingSenderId: "568195290486",
    appId: "1:568195290486:web:98c96092ce59bac7299657",
    measurementId: "G-Q6HEQ7FXZ7"
};


*/

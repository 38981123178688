import FileSaver from "file-saver";
import JSZip from "jszip";

export const taramaHizmetiIndir = async (
  isim,
  eposta,
  telefonNumarası,
  firmaIsmi,
  url1,
  url2,
  url3,
  url4,
  dosyaIsmi,
  malzemeCesidi,
  boyutBilgisi,
  aciklama,
  teslimSuresi
) => {
  const zip = new JSZip();
  const imageUrls = [url1, url2, url3, url4];

  const imagePromises = imageUrls.map(async (imageUrl, index) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    zip.file(`resim${index + 1}.jpg`, blob);
  });

  const textContent = `
Tarama Modelleme Hizmeti
————————————————————————
İsim: ${isim}
Eposta: ${eposta}
Firma: ${firmaIsmi}
Telefon: ${telefonNumarası}
Teslim Süresi: ${teslimSuresi}

Malzeme Çeşidi: ${malzemeCesidi}
Boyut Bilgisi: ${boyutBilgisi}
Musteri Aciklama:
${aciklama}
`;
  zip.file("Bilgilendirme.txt", textContent);

  await Promise.all(imagePromises);

  zip.generateAsync({ type: "blob" }).then((content) => {
    FileSaver.saveAs(content, `${firmaIsmi}TaramaHizmeti.zip`);
  });
};

export const prototipHizmetiIndir = async (
  isim,
  eposta,
  telefonNumarası,
  firmaIsmi,
  stlUrl,
  dosyaIsmi,
  boyutBilgisi,
  malzemeAdet,
  malzemeCesidi,
  baskiTeknolojisi
) => {
  const zip = new JSZip();

  const textContent = `
Prototipleme Hizmeti
————————————————————
İsim: ${isim}
Eposta: ${eposta}
Firma: ${firmaIsmi}
Telefon: ${telefonNumarası}

Malzeme Çeşidi: ${malzemeCesidi}
Boyut Bilgisi: ${boyutBilgisi}
Adet: ${malzemeAdet}
Baski Teknolojisi: ${baskiTeknolojisi}


`;
  const stlResponse = await fetch(stlUrl);
  const stlBlob = await stlResponse.blob();
  zip.file(`${dosyaIsmi}`, stlBlob);

  // Text content
  const textBlob = new Blob([textContent], { type: "text/plain" });
  zip.file("Bilgilendirme.txt", textBlob);

  zip.generateAsync({ type: "blob" }).then((content) => {
    FileSaver.saveAs(content, `${firmaIsmi}Prototipleme.zip`);
  });
};

export const kaliteKontrolHizmetIndir = async (
  isim,
  eposta,
  telefonNumarası,
  firmaIsmi,
  url1,
  url2,
  url3,
  url4,
  malzemeCesidi,
  olculecekParcaSayisi,
  teknikResimFile,
  cadDataFile,
  cadDataFileIsim,
  teknikResimIsim
) => {
  const zip = new JSZip();

  const textContent = `
  Kalite Kontrol Hizmeti
  ——————————————————————
  İsim: ${isim}
  Eposta: ${eposta}
  Firma: ${firmaIsmi}
  Telefon: ${telefonNumarası}
  
  Malzeme Çeşidi: ${malzemeCesidi}
  Olculecek Parca Sayisi: ${olculecekParcaSayisi}
  
  
  `;

  const imageUrls = [url1, url2, url3, url4];

  const imagePromises = imageUrls.map(async (imageUrl, index) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    zip.file(`resim${index + 1}.jpg`, blob);
  });

  if (teknikResimFile && teknikResimIsim !== "bos") {
    const teknikResimDownload = await fetch(teknikResimFile);
    const teknikBlob = await teknikResimDownload.blob();
    zip.file(`${teknikResimIsim}`, teknikBlob);
  }

  if (cadDataFile && cadDataFileIsim !== "bos") {
    const cadData = await fetch(cadDataFile);
    const cadBlob = await cadData.blob();
    zip.file(`${cadDataFileIsim}`, cadBlob);
  }
  await Promise.all(imagePromises);

  // Text content
  const textBlob = new Blob([textContent], { type: "text/plain" });
  zip.file("Bilgilendirme.txt", textBlob);

  zip.generateAsync({ type: "blob" }).then((content) => {
    FileSaver.saveAs(content, `${firmaIsmi}KaliteKontrol.zip`);
  });
};
